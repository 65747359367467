import { ServiceActivity } from './useServiceActivityTable';
export const successionCompleteMockData: ServiceActivity[] = [
    {
        contact: {
            firstName: 'John',
            lastName: 'Doe',
            avatar: 'https://i.pravatar.cc/150?img=1',
        },
        startDate: '01/15/2023',

        activeServices: ['SUCCESSION_PLAN', 'ASSET_PLAN'],
        lastOutreachDate: '06/01/2023',
    },
    {
        contact: {
            firstName: 'Jane',
            lastName: 'Smith',
            avatar: 'https://i.pravatar.cc/150?img=2',
        },
        startDate: '11/20/2022',

        activeServices: ['DISASTER_PLAN'],
        lastOutreachDate: '04/10/2023',
    },
    {
        contact: {
            firstName: 'Alice',
            lastName: 'Brown',
            avatar: 'https://i.pravatar.cc/150?img=3',
        },
        startDate: '02/18/2023',

        activeServices: ['SUCCESSION_PLAN'],
        lastOutreachDate: '07/15/2023',
    },
    {
        contact: {
            firstName: 'Bob',
            lastName: 'Johnson',
            avatar: 'https://i.pravatar.cc/150?img=4',
        },
        startDate: '10/25/2022',

        activeServices: ['ASSET_PLAN', 'DISASTER_PLAN'],
        lastOutreachDate: '03/20/2023',
    },
    {
        contact: {
            firstName: 'Carol',
            lastName: 'Williams',
            avatar: 'https://i.pravatar.cc/150?img=5',
        },
        startDate: '03/12/2023',

        activeServices: ['SUCCESSION_PLAN', 'ASSET_PLAN', 'DISASTER_PLAN'],
        lastOutreachDate: '08/30/2023',
    },
    {
        contact: {
            firstName: 'David',
            lastName: 'Miller',
            avatar: 'https://i.pravatar.cc/150?img=6',
        },
        startDate: '09/05/2022',

        activeServices: ['DISASTER_PLAN'],
        lastOutreachDate: '02/14/2023',
    },
    {
        contact: {
            firstName: 'Eva',
            lastName: 'Wilson',
            avatar: 'https://i.pravatar.cc/150?img=7',
        },
        startDate: '04/25/2023',

        activeServices: ['SUCCESSION_PLAN', 'ASSET_PLAN'],
        lastOutreachDate: '06/20/2023',
    },
    {
        contact: {
            firstName: 'Frank',
            lastName: 'Moore',
            avatar: 'https://i.pravatar.cc/150?img=8',
        },
        startDate: '07/30/2022',

        activeServices: ['DISASTER_PLAN'],
        lastOutreachDate: '01/12/2023',
    },
    {
        contact: {
            firstName: 'Grace',
            lastName: 'Taylor',
            avatar: 'https://i.pravatar.cc/150?img=9',
        },
        startDate: '05/18/2023',

        activeServices: ['SUCCESSION_PLAN', 'ASSET_PLAN', 'DISASTER_PLAN'],
        lastOutreachDate: '07/25/2023',
    },
    {
        contact: {
            firstName: 'Henry',
            lastName: 'Anderson',
            avatar: 'https://i.pravatar.cc/150?img=10',
        },
        startDate: '06/10/2022',

        activeServices: ['ASSET_PLAN'],
        lastOutreachDate: '01/05/2023',
    },
    {
        contact: {
            firstName: 'Isabel',
            lastName: 'Thomas',
            avatar: 'https://i.pravatar.cc/150?img=11',
        },
        startDate: '07/15/2023',

        activeServices: ['DISASTER_PLAN'],
        lastOutreachDate: '09/10/2023',
    },
    {
        contact: {
            firstName: 'Jack',
            lastName: 'Jackson',
            avatar: 'https://i.pravatar.cc/150?img=12',
        },
        startDate: '05/22/2022',

        activeServices: ['SUCCESSION_PLAN'],
        lastOutreachDate: '01/30/2023',
    },
    {
        contact: {
            firstName: 'Karen',
            lastName: 'White',
            avatar: 'https://i.pravatar.cc/150?img=13',
        },
        startDate: '08/10/2023',

        activeServices: ['ASSET_PLAN', 'DISASTER_PLAN'],
        lastOutreachDate: '10/05/2023',
    },
    {
        contact: {
            firstName: 'Louis',
            lastName: 'Harris',
            avatar: 'https://i.pravatar.cc/150?img=14',
        },
        startDate: '04/15/2022',

        activeServices: ['SUCCESSION_PLAN', 'ASSET_PLAN'],
        lastOutreachDate: '02/28/2023',
    },
    {
        contact: {
            firstName: 'Maria',
            lastName: 'Martin',
            avatar: 'https://i.pravatar.cc/150?img=15',
        },
        startDate: '09/20/2023',

        activeServices: ['DISASTER_PLAN'],
        lastOutreachDate: '12/01/2023',
    },
    {
        contact: {
            firstName: 'Nick',
            lastName: 'Thompson',
            avatar: 'https://i.pravatar.cc/150?img=16',
        },
        startDate: '03/18/2022',

        activeServices: ['ASSET_PLAN'],
        lastOutreachDate: '01/15/2023',
    },
    {
        contact: {
            firstName: 'Olivia',
            lastName: 'Garcia',
            avatar: 'https://i.pravatar.cc/150?img=17',
        },
        startDate: '10/12/2023',

        activeServices: ['SUCCESSION_PLAN'],
        lastOutreachDate: '11/25/2023',
    },
    {
        contact: {
            firstName: 'Paul',
            lastName: 'Martinez',
            avatar: 'https://i.pravatar.cc/150?img=18',
        },
        startDate: '02/25/2022',

        activeServices: ['DISASTER_PLAN'],
        lastOutreachDate: '03/05/2023',
    },
    {
        contact: {
            firstName: 'Quincy',
            lastName: 'Robinson',
            avatar: 'https://i.pravatar.cc/150?img=19',
        },
        startDate: '11/05/2023',

        activeServices: ['ASSET_PLAN'],
        lastOutreachDate: '12/15/2023',
    },
    {
        contact: {
            firstName: 'Rachel',
            lastName: 'Clark',
            avatar: 'https://i.pravatar.cc/150?img=20',
        },
        startDate: '01/10/2022',

        activeServices: ['SUCCESSION_PLAN', 'DISASTER_PLAN'],
        lastOutreachDate: '02/20/2023',
    },
    {
        contact: {
            firstName: 'Sam',
            lastName: 'Rodriguez',
            avatar: 'https://i.pravatar.cc/150?img=21',
        },
        startDate: '12/08/2023',

        activeServices: ['ASSET_PLAN', 'DISASTER_PLAN'],
        lastOutreachDate: '01/05/2024',
    },
];
export const successionInProgressData = [
    {
        farm: {
            name: 'Doe Farms',
            avatar: 'https://i.pravatar.cc/150?img=1',
        },
        startDate: '01/01/2023',
        lastUpdated: '06/01/2023',
        primaryContact: {
            firstName: 'John',
            lastName: 'Doe',
        },
        stepNumber: 1,
        stepName: 'Data Uploaded',
    },
    {
        farm: {
            name: 'Smith Orchards',
            avatar: 'https://i.pravatar.cc/150?img=2',
        },
        startDate: '11/01/2022',
        lastUpdated: '04/01/2023',
        primaryContact: {
            firstName: 'Jane',
            lastName: 'Smith',
        },
        stepNumber: 2,
        stepName: 'No log-in recorded',
    },
    {
        farm: {
            name: 'Brown Fields',
            avatar: 'https://i.pravatar.cc/150?img=3',
        },
        startDate: '02/01/2023',
        lastUpdated: '07/01/2023',
        primaryContact: {
            firstName: 'Alice',
            lastName: 'Brown',
        },
        stepNumber: 3,
        stepName: 'Data Uploaded Failed',
    },
    {
        farm: {
            name: 'Johnson Gardens',
            avatar: 'https://i.pravatar.cc/150?img=4',
        },
        startDate: '10/01/2022',
        lastUpdated: '03/01/2023',
        primaryContact: {
            firstName: 'Bob',
            lastName: 'Johnson',
        },
        stepNumber: 4,
        stepName: 'Completed',
    },
    {
        farm: {
            name: 'Williams Vineyards',
            avatar: 'https://i.pravatar.cc/150?img=5',
        },
        startDate: '03/01/2023',
        lastUpdated: '08/01/2023',
        primaryContact: {
            firstName: 'Carol',
            lastName: 'Williams',
        },
        stepNumber: 5,
        stepName: 'In 3rd Party Review',
    },
    {
        farm: {
            name: 'Miller Farms',
            avatar: 'https://i.pravatar.cc/150?img=6',
        },
        startDate: '09/01/2022',
        lastUpdated: '02/01/2023',
        primaryContact: {
            firstName: 'David',
            lastName: 'Miller',
        },
        stepNumber: 6,
        stepName: 'Submission Rejected',
    },
    {
        farm: {
            name: 'Wilson Orchards',
            avatar: 'https://i.pravatar.cc/150?img=7',
        },
        startDate: '04/01/2023',
        lastUpdated: '06/01/2023',
        primaryContact: {
            firstName: 'Eva',
            lastName: 'Wilson',
        },
        stepNumber: 1,
        stepName: 'Data Uploaded',
    },
    {
        farm: {
            name: 'Moore Fields',
            avatar: 'https://i.pravatar.cc/150?img=8',
        },
        startDate: '07/01/2022',
        lastUpdated: '01/01/2023',
        primaryContact: {
            firstName: 'Frank',
            lastName: 'Moore',
        },
        stepNumber: 2,
        stepName: 'No log-in recorded',
    },
    {
        farm: {
            name: 'Taylor Farms',
            avatar: 'https://i.pravatar.cc/150?img=9',
        },
        startDate: '05/01/2023',
        lastUpdated: '07/01/2023',
        primaryContact: {
            firstName: 'Grace',
            lastName: 'Taylor',
        },
        stepNumber: 3,
        stepName: 'Data Uploaded Failed',
    },
    {
        farm: {
            name: 'Anderson Gardens',
            avatar: 'https://i.pravatar.cc/150?img=10',
        },
        startDate: '06/01/2022',
        lastUpdated: '01/01/2023',
        primaryContact: {
            firstName: 'Henry',
            lastName: 'Anderson',
        },
        stepNumber: 4,
        stepName: 'Completed',
    },
    {
        farm: {
            name: 'Thomas Vineyards',
            avatar: 'https://i.pravatar.cc/150?img=11',
        },
        startDate: '07/01/2023',
        lastUpdated: '09/01/2023',
        primaryContact: {
            firstName: 'Isabel',
            lastName: 'Thomas',
        },
        stepNumber: 5,
        stepName: 'In 3rd Party Review',
    },
    {
        farm: {
            name: 'Jackson Farms',
            avatar: 'https://i.pravatar.cc/150?img=12',
        },
        startDate: '05/01/2022',
        lastUpdated: '01/01/2023',
        primaryContact: {
            firstName: 'Jack',
            lastName: 'Jackson',
        },
        stepNumber: 6,
        stepName: 'Submission Rejected',
    },
    {
        farm: {
            name: 'White Orchards',
            avatar: 'https://i.pravatar.cc/150?img=13',
        },
        startDate: '08/01/2023',
        lastUpdated: '10/01/2023',
        primaryContact: {
            firstName: 'Karen',
            lastName: 'White',
        },
        stepNumber: 1,
        stepName: 'Data Uploaded',
    },
    {
        farm: {
            name: 'Harris Fields',
            avatar: 'https://i.pravatar.cc/150?img=14',
        },
        startDate: '04/01/2022',
        lastUpdated: '02/01/2023',
        primaryContact: {
            firstName: 'Louis',
            lastName: 'Harris',
        },
        stepNumber: 2,
        stepName: 'No log-in recorded',
    },
    {
        farm: {
            name: 'Martin Farms',
            avatar: 'https://i.pravatar.cc/150?img=15',
        },
        startDate: '09/01/2023',
        lastUpdated: '12/01/2023',
        primaryContact: {
            firstName: 'Maria',
            lastName: 'Martin',
        },
        stepNumber: 3,
        stepName: 'Data Uploaded Failed',
    },
];
