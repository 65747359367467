import React, { useEffect } from 'react';
import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    maxLength,
    maxLengthForArrayField,
    minLength,
    minLengthForArrayField,
    required,
} from '../../utils/validation';
import { useLazyGetUsersOfOrgQuery } from '../../api/users/organizationsAPI';
import { Box, Grid, Typography } from '@mui/material';
import Loader from '../../design/BaseLoader';
import {
    DecodedToken,
    priorityOptions,
    statusOptions,
    userListConvertor,
} from './utils';
import FormPage from '../../reusable/FormUI/FormPage';
import TaskDocuments from './TaskDocuments';
import { useFormState } from 'react-final-form';
import { getTokenData } from '../../utils/jwtUtils';
import RelatesToForm from './RelatesToForm';

export default function TaskFormUpdate() {
    const decoded = getTokenData() as DecodedToken;
    const organizationId = decoded ? decoded?.organizationId : null;
    const [getItems, { data: userList, isLoading }] =
        useLazyGetUsersOfOrgQuery();

    useEffect(() => {
        if (organizationId) {
            getItems({
                organizationId: organizationId,
                sort: `firstName,desc`,
            });
        }
    }, [organizationId]);
    const { values } = useFormState();

    if (isLoading) {
        return (
            <Grid
                container
                sx={{ height: '100%' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    const formTaskConfig: FormConfig[] = [
        {
            formField: {
                scheme: 'half',
                name: 'status',
                type: 'select',
                label: 'Status',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: statusOptions,
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'dueDate',
                type: 'datepicker',
                label: 'Due Date',
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
                format: 'MMM dd, yyyy',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'assignTo',
                type: 'select',
                label: 'Assign To',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: userListConvertor(userList),
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'priority',
                type: 'select',
                label: 'Priority',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: priorityOptions,
            },
        },
        {
            formField: {
                name: 'description',
                type: 'textarea',
                label: 'Description',
                isRequired: true,
                validation: composeValidators(
                    required,
                    minLength(1),
                    maxLength(1024),
                ),
            },
            renderProps: {
                placeholder: '',
                resize: 'none',
                isDisabled: false,
            },
        },
        {
            formField: {
                name: 'watchers',
                type: 'autocomplete',
                label: 'Task Watcher',
                validation: composeValidators(
                    maxLengthForArrayField(256),
                    minLengthForArrayField(1),
                ),
            },
            renderProps: {
                freeSolo: false,
                isDisabled: false,
                size: 'small',
                options: userListConvertor(userList),
            },
        },
    ];
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={3}
            width={'100%'}>
            {values?.name && (
                <Typography variant={'font20'} fontWeight={'medium'}>
                    {values?.name}
                </Typography>
            )}
            <FormPage formConfig={formTaskConfig} />
            <RelatesToForm />
            <TaskDocuments />
        </Box>
    );
}
