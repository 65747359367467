import React from 'react';
import { Autocomplete as MuiAutocomplete, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';

export interface AutocompleteOption {
    id: string;
    name: string;
    type?: string;
    parentId?: string;
}

export interface Autocomplete {
    onChange: (value: (AutocompleteOption | string)[]) => void;
    placeholder?: string;
    isDisabled?: boolean;
    errorText?: string;
    name?: string;
    size?: 'small' | 'medium';
    freeSolo?: boolean;
    sizeSameAsInputField?: boolean;
    value?: AutocompleteOption[] | string[];
    options?: AutocompleteOption[];
}

export default function AutocompleteField({
    value,
    size,
    errorText,
    onChange,
    placeholder,
    isDisabled,
    name,
    freeSolo = false,
    sizeSameAsInputField = false,
    options = [],
}: Autocomplete) {
    const { classes } = useStyles();

    return (
        <FormControl
            variant="outlined"
            size={size}
            error={!!errorText}
            disabled={isDisabled}>
            <MuiAutocomplete
                id={name}
                freeSolo={freeSolo}
                multiple
                disabled={isDisabled}
                autoSelect
                filterSelectedOptions
                getOptionKey={(option: string | AutocompleteOption) =>
                    typeof option === 'string' ? option : option.id
                }
                options={options.map((option) => ({
                    id: option.id,
                    name: option.name,
                    type: option.type,
                    parentId: option.parentId,
                }))}
                getOptionLabel={(option: AutocompleteOption | string) =>
                    typeof option === 'string' ? option : option?.name
                }
                onChange={(_, newValues: (AutocompleteOption | string)[]) => {
                    onChange(newValues);
                }}
                value={value || []}
                isOptionEqualToValue={(option, value) =>
                    typeof option === 'string'
                        ? option === value
                        : option.id ===
                          (typeof value === 'string' ? value : value.id)
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={!!errorText}
                        helperText={errorText}
                        label={placeholder}
                        variant="outlined"
                        disabled={isDisabled}
                        InputProps={
                            sizeSameAsInputField
                                ? {
                                      ...params.InputProps,
                                      classes: {
                                          root: classes.inputRoot,
                                          input: classes.input,
                                      },
                                  }
                                : params.InputProps
                        }
                        InputLabelProps={
                            sizeSameAsInputField
                                ? {
                                      className: classes.placeholder,
                                  }
                                : params.InputLabelProps
                        }
                    />
                )}
                classes={
                    sizeSameAsInputField
                        ? {
                              inputRoot: classes.inputRoot,
                              tag: classes.chipRoot,
                          }
                        : undefined
                }
            />
        </FormControl>
    );
}

const useStyles = makeStyles()(() => ({
    inputRoot: {
        minHeight: '38.99px !important',
        padding: '2px !important',
    },
    input: {
        padding: '4px 6px !important',
        height: 'initial',
    },
    placeholder: {
        fontSize: '14px',
    },
    chipRoot: {
        height: '26px !important',
        fontSize: '14px !important',
        margin: '1px !important',
    },
}));
