import React, { ComponentType } from 'react';
import { Field } from 'react-final-form';
import { Grid, Stack } from '@mui/material';
import FieldLabelWithTooltip from '../Fields/FieldLabelWithTooltip';
import { GenericTooltip } from '../Tooltip/InfoTooltip';

import {
    ComponentForm,
    DrivenTypeComponent,
    FieldScheme,
    genericComponents,
    TypesOfComponent,
} from '../Fields/utils';

const fieldSchemeMapping: Record<FieldScheme, number> = {
    quarter: 3,
    third: 4,
    half: 6,
    full: 12,
};

export interface GenericField {
    scheme?: FieldScheme;
    name: string;
    //eslint-disable-next-line
    validation?: any;
    type: ComponentForm;
    //eslint-disable-next-line
    onChange?: (e: any) => void;
    //eslint-disable-next-line
    onBlur?: (e: any) => void;
    label?: string;
    isRequired?: boolean;
    isHidden?: boolean;
    tooltip?: GenericTooltip;
    labelColor?: LabelColor;
}

export type LabelColor = 'white' | 'regular';
type OwnProps = DrivenTypeComponent & GenericField;
export default function GenericFormInput({
    name,
    validation,
    onChange,
    onBlur,
    type,
    tooltip,
    label,
    isRequired,
    isHidden,
    scheme = 'full',
    labelColor,
    ...inputProps
}: OwnProps) {
    if (isHidden) return null;
    const Component = genericComponents[
        type
    ] as ComponentType<TypesOfComponent>;
    return (
        <Grid item xs={fieldSchemeMapping[scheme]}>
            <Field name={name} validate={validation}>
                {({ input, meta }) => (
                    <Stack gap={1}>
                        <FieldLabelWithTooltip
                            tooltip={tooltip}
                            label={label}
                            isRequired={isRequired}
                            labelColor={labelColor}
                        />
                        <Component
                            {...input}
                            {...inputProps}
                            //eslint-disable-next-line
                            onChange={(e: any) => {
                                input.onChange(e);
                                onChange && onChange(e);
                            }}
                            //eslint-disable-next-line
                            onBlur={(e: any) => {
                                input.onBlur(e);
                                onBlur && onBlur(e);
                            }}
                            value={input.value}
                            errorText={meta.touched && meta.error}
                            isDisabled={
                                meta.submitting || inputProps.isDisabled
                            }
                        />
                    </Stack>
                )}
            </Field>
        </Grid>
    );
}
