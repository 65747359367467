import React, { useContext, useState } from 'react';
import { TableColumn } from '../../../../design/GenericTable/GenericTable';

import { useSorting } from '../../../../design/GenericTable/useSorting';
import { usePagination } from '../../../../design/GenericTable/useBackendPagination';

import { Typography } from '@mui/material';
import ActionsColumn from '../ActionsColumn';
import { SelectedRowContext } from '../SelectedRowContext';

import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';

import {
    useDeleteLandPartnerMutation,
    useGetLandPartnersArchiveQuery,
    useRestoreLandPartnerMutation,
} from '../../../../api/landpartners/landPartnersAPI';
import { dateTimeConverterFromUTC } from '../../../../utils/dateFormat';

export interface LandPartnerArchive {
    id: string;
    landPartnerName: string;
    archiveDate: string;
    archivedBy: string;
    primaryContact: string;
    email: string;
    relationshipStartDate: string;
    createdBy: string;
    createdDate: string;
}

export interface LandPartnerArchiveResponse {
    id: string;
    name: string;
    email: string;
    primaryContact: PrimaryContact;
    createdBy: CreatedBy;
    createdAt: string;
    archivedBy: ArchivedBy;
    archivedAt: string;
    relationshipStartDate: string;
}

export interface PrimaryContact {
    id: string;
    firstName: string;
    lastName: string;
}

export interface CreatedBy {
    id: string;
    firstName: string;
    lastName: string;
}

export interface ArchivedBy {
    id: string;
    firstName: string;
    lastName: string;
}

export default function useTableArchiveLPConfig() {
    const [isShowing, setIsShowing] = useState(false);
    const [deleteData, setDeleteData] = useState<LandPartnerArchive | null>(
        null,
    );

    const { sortKey, sortOrder, handleSort } = useSorting('name', 'asc');
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 100);
    const { setSelectedRow } = useContext(SelectedRowContext);
    const { handleSubmit } = useHandleSubmitRTK({
        useRtkHook: useRestoreLandPartnerMutation,
        onSuccess: () => setSelectedRow(null),
        successMessage: 'Land Partner restored successfully',
    });
    const { handleSubmit: handleDelete } = useHandleSubmitRTK({
        useRtkHook: useDeleteLandPartnerMutation,
        onSuccess: () => {
            setIsShowing(false);
            setSelectedRow(null);
        },
        successMessage: 'Land Partner deleted successfully',
    });

    function handleDeleteClick(data: LandPartnerArchive) {
        setIsShowing(true);
        setDeleteData(data);
    }

    const { data, isLoading } = useGetLandPartnersArchiveQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    const handleRowClick = (rowData: LandPartnerArchive) => {
        setSelectedRow(rowData);
    };
    const columns: TableColumn<LandPartnerArchive>[] = [
        {
            header: {
                label: 'Name',
                sortable: true,
                sorting: {
                    id: 'name',
                    direction: sortKey === 'name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'name',
                },
                onClick: handleSort,
            },
            cellRender: (lp) => (
                <Typography color={'text.link'}>
                    {lp.landPartnerName}
                </Typography>
            ),
            maxColumnWidth: 200,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                customizeKey: 'archiveDate',
                label: 'Archived Date',
                sortable: false,
                sorting: {
                    id: 'archiveDate',
                    direction: sortKey === 'archiveDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'archiveDate',
                },
                onClick: handleSort,
            },
            cellRender: (lp) => <>{lp.archiveDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                customizeKey: 'archivedBy',
                label: 'Archived By',
                sortable: false,
                sorting: {
                    id: 'archivedBy',
                    direction: sortKey === 'archivedBy' ? sortOrder : 'desc',
                    isSorted: sortKey === 'archivedBy',
                },
                onClick: handleSort,
            },
            cellRender: (lp) => <>{lp?.archivedBy}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                customizeKey: 'actions',
                label: 'Actions',
                sortable: false,
            },
            cellRender: (lp) => (
                <ActionsColumn
                    id={lp.id}
                    onDelete={() => handleDeleteClick(lp)}
                    onRestore={() => handleSubmit(lp.id)}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '40%',
        },
    ];

    const tableConfig = {
        columns,
        pageable: false,
        handlePageChange,
        onClick: handleRowClick,
        handleItemsPerPageChange,
        initialPageSize: 5,
        initialPage: 1,
    };
    const totalCount = data?.totalCount || 0;

    return {
        data: data?.data ? data?.data.map(dataConvertorFromResponse) : [],
        totalCount,
        tableConfig,
        isLoading,
        deleteData,
        isShowing,
        setIsShowing,
        handleDelete,
    };
}

function dataConvertorFromResponse(
    data: LandPartnerArchiveResponse,
): LandPartnerArchive {
    return {
        id: data.id,
        landPartnerName: data.name,
        archiveDate: dateTimeConverterFromUTC(data.archivedAt),
        archivedBy: `${data.archivedBy.firstName} ${data.archivedBy.lastName}`,
        primaryContact: `${data.primaryContact.firstName} ${data.primaryContact.lastName}`,
        email: data.email || 'N/A',
        createdBy: `${data.createdBy.firstName} ${data.createdBy.lastName}`,
        createdDate: dateTimeConverterFromUTC(data.createdAt),
        relationshipStartDate: data.relationshipStartDate || 'N/A',
    };
}
