import { Box } from '@mui/material';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    maxLengthForArrayField,
    minLengthForArrayField,
} from '../../utils/validation';
import { userListConvertor } from './utils';
import { invalidFileType, tooLargeFile } from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';
import { UserList } from './TaskFormCreate';

interface OwnProps {
    userList: UserList[];
}
export default function WatchersAndDocumentsUploadForm({ userList }: OwnProps) {
    return (
        <Box>
            <FormPage formConfig={watchersAndDocumentsUploadConfig(userList)} />
        </Box>
    );
}

function watchersAndDocumentsUploadConfig(userList: UserList[]): FormConfig[] {
    return [
        {
            formField: {
                name: 'watchers',
                type: 'autocomplete',
                label: 'Task Watcher',
                validation: composeValidators(
                    maxLengthForArrayField(256),
                    minLengthForArrayField(1),
                ),
            },
            renderProps: {
                freeSolo: false,
                isDisabled: false,
                size: 'small',
                options: userListConvertor(userList),
            },
        },
        {
            formField: {
                name: 'documents',
                type: 'fileupload',
                validation: composeValidators(
                    tooLargeFile(MAX_FILE_SIZE),
                    invalidFileType([
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'image/bmp',
                        'application/pdf',
                    ]),
                ),
            },
            renderProps: {
                filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
                placeholder: 'Or select from your computer (Max size 10MB)',
                helperText: 'Drag supporting document here (optional)',
                multipleFiles: true,
                isDisabled: false,
                isHidden: false,
            },
        },
    ];
}
