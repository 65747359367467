import React, { useEffect, useMemo, useState } from 'react';
import GenericTable, {
    Table,
    TableColumn,
} from '../../design/GenericTable/GenericTable';
import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';

import {
    useDeleteLeaseDraftMutation,
    useGetLeaseDraftsQuery,
    useLazyGetLeaseDraftsQuery,
} from '../../api/leases/leaseAPI';
import PATHS from '../../router/paths';
import { Lease } from './TableConfig';
import { Box, IconButton } from '@mui/material';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove_icon.svg';
import ConfirmModal from '../../layouts/ConfirmModal/ConfirmModal';
import LinkText from '../../design/Fields/LinkText';
import { dateTimeConverterFromTimestamp } from '../../utils/dateFormat';
import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import useManagePermission from '../../hooks/useManagePermission';

export interface DraftLeases {
    id: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    lastUpdated: number;
    name: string;
    type: string;
    completion: number;
    draftData: Lease;
}

interface OwnProps {
    isSelected: (items: DraftLeases) => boolean;
    toggleSelection: (items: DraftLeases) => void;
    toggleSelectionAll: (items: DraftLeases[]) => void;
    isSelectedAll: (items: DraftLeases[]) => boolean;
}

export default function DraftLeaseTable({
    isSelected,
    toggleSelection,
    toggleSelectionAll,
    isSelectedAll,
}: OwnProps) {
    const { settings, saveSettings } = useSettingsFromStorage('DRAFT_LEASES');
    const { sort, page, sortDirection, size } = settings;
    const [id, setId] = useState<string | null>(null);
    const [deleteIsShowing, setDeleteIsShowing] = useState<boolean>(false);

    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'createdAt',
        sortDirection || 'asc',
    );

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    const { data: initData } = useGetLeaseDraftsQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    const [getItems, { data: sortedData }] = useLazyGetLeaseDraftsQuery();
    const [deleteLeaseDraft] = useDeleteLeaseDraftMutation();
    const { checkPermission } = useManagePermission();

    const onDelete = (id: string) => {
        id && setId(id);
        setDeleteIsShowing(true);
    };
    const handleDelete = (id: string) => {
        deleteLeaseDraft({ id });
        setDeleteIsShowing(false);
        setId(null);
    };
    const findLease = (id: string) => {
        return data?.find((item: DraftLeases) => item.id === id);
    };
    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const data = useMemo(() => {
        return sortedData?.data || initData?.data || [];
    }, [sortedData, initData]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || initData?.totalCount || 0;
    }, [sortedData, initData]);
    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });
    const columns: TableColumn<DraftLeases>[] = [
        {
            header: {
                label: 'Lease Name',
                sortable: true,
                sorting: {
                    id: 'name',
                    direction: sortKey === 'name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'name',
                },
                onClick: handleSort,
            },
            cellRender: (lease) => (
                <LinkText
                    to={
                        checkPermission('leases', 'modify')
                            ? `/${PATHS.landPortfolio}/${PATHS.manage}/${PATHS.create}`
                            : ''
                    }
                    state={{ ...lease?.draftData, isCopy: true, id: lease.id }}
                    text={lease?.name}
                    variant={'font14'}
                />
            ),

            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Last Updated',
                sortable: true,
                sorting: {
                    id: 'lastUpdated',
                    direction: sortKey === 'lastUpdated' ? sortOrder : 'desc',
                    isSorted: sortKey === 'lastUpdated',
                },
                onClick: handleSort,
            },
            cellRender: (lease) =>
                dateTimeConverterFromTimestamp(lease.lastUpdated),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Complete',
                sortable: true,
                sorting: {
                    id: 'completion',
                    direction: sortKey === 'completion' ? sortOrder : 'desc',
                    isSorted: sortKey === 'completion',
                },
                onClick: handleSort,
            },
            cellRender: (lease) => <>{lease.completion + ' %'}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Actions',
                sortable: false,
            },
            cellRender: (lease) => (
                <Box>
                    <IconButton onClick={() => onDelete(lease.id)}>
                        <RemoveIcon />
                    </IconButton>
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];

    const tableConfig: Table<DraftLeases> = {
        columns: columns,
        multiselect: true,
        expandable: false,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        isSelected: isSelected,
        toggleSelection: toggleSelection,
        toggleSelectionAll: toggleSelectionAll,
        isSelectedAll: isSelectedAll,
        initialPage: page,
        initialPageSize: size,
    };
    return (
        <>
            <GenericTable
                data={data}
                totalCount={totalCount}
                tableConfig={tableConfig}
            />
            {id && (
                <ConfirmModal
                    isShowing={deleteIsShowing}
                    hide={() => setDeleteIsShowing(false)}
                    title={`Are you sure you want to delete ${
                        findLease(id)?.name
                    } ?`}
                    info={`You are about to delete  ${
                        findLease(id)?.name
                    }. This action cannot be undone`}
                    onSubmit={() => handleDelete(id)}
                />
            )}
        </>
    );
}
