import { GrowersTable } from './useGrowersTable';
export const mockData: GrowersTable[] = [
    {
        grower: {
            firstName: 'John',
            lastName: 'Doe',
            avatar: 'https://i.pravatar.cc/150?img=1',
        },
        farmName: 'Doe Farms',
        contactNumber: '555-1234',
        status: 'ACTIVE',
    },
    {
        grower: {
            firstName: 'Jane',
            lastName: 'Smith',
            avatar: 'https://i.pravatar.cc/150?img=2',
        },
        farmName: 'Smith Orchards',
        contactNumber: '555.5678.555',
        status: 'INACTIVE',
    },
    {
        grower: {
            firstName: 'Alice',
            lastName: 'Brown',
            avatar: 'https://i.pravatar.cc/150?img=3',
        },
        farmName: 'Brown Fields',
        contactNumber: '555.8765.555',
        status: 'PROSPECT',
    },
    {
        grower: {
            firstName: 'Bob',
            lastName: 'Johnson',
            avatar: 'https://i.pravatar.cc/150?img=4',
        },
        farmName: 'Johnson Gardens',
        contactNumber: '555.4321',
        status: 'SUSPENDED',
    },
    {
        grower: {
            firstName: 'Carol',
            lastName: 'Williams',
            avatar: 'https://i.pravatar.cc/150?img=5',
        },
        farmName: 'Williams Vineyards',
        contactNumber: '555-2468',
        status: 'ACTIVE',
    },
    {
        grower: {
            firstName: 'David',
            lastName: 'Miller',
            avatar: 'https://i.pravatar.cc/150?img=6',
        },
        farmName: 'Miller Farms',
        contactNumber: '555-1357',
        status: 'INACTIVE',
    },
    {
        grower: {
            firstName: 'Eva',
            lastName: 'Wilson',
            avatar: 'https://i.pravatar.cc/150?img=7',
        },
        farmName: 'Wilson Orchards',
        contactNumber: '555-9753',
        status: 'PROSPECT',
    },
    {
        grower: {
            firstName: 'Frank',
            lastName: 'Moore',
            avatar: 'https://i.pravatar.cc/150?img=8',
        },
        farmName: 'Moore Fields',
        contactNumber: '555-8642',
        status: 'SUSPENDED',
    },
    {
        grower: {
            firstName: 'Grace',
            lastName: 'Taylor',
            avatar: 'https://i.pravatar.cc/150?img=9',
        },
        farmName: 'Taylor Farms',
        contactNumber: '555-7531',
        status: 'ACTIVE',
    },
    {
        grower: {
            firstName: 'Henry',
            lastName: 'Anderson',
            avatar: 'https://i.pravatar.cc/150?img=10',
        },
        farmName: 'Anderson Gardens',
        contactNumber: '555-6420',
        status: 'INACTIVE',
    },
    {
        grower: {
            firstName: 'Isabel',
            lastName: 'Thomas',
            avatar: 'https://i.pravatar.cc/150?img=11',
        },
        farmName: 'Thomas Vineyards',
        contactNumber: '555-5319',
        status: 'PROSPECT',
    },
    {
        grower: {
            firstName: 'Jack',
            lastName: 'Jackson',
            avatar: 'https://i.pravatar.cc/150?img=12',
        },
        farmName: 'Jackson Farms',
        contactNumber: '555-4208',
        status: 'SUSPENDED',
    },
    {
        grower: {
            firstName: 'Karen',
            lastName: 'White',
            avatar: 'https://i.pravatar.cc/150?img=13',
        },
        farmName: 'White Orchards',
        contactNumber: '555-3197',
        status: 'ACTIVE',
    },
    {
        grower: {
            firstName: 'Louis',
            lastName: 'Harris',
            avatar: 'https://i.pravatar.cc/150?img=14',
        },
        farmName: 'Harris Fields',
        contactNumber: '555-2086',
        status: 'INACTIVE',
    },
    {
        grower: {
            firstName: 'Maria',
            lastName: 'Martin',
            avatar: 'https://i.pravatar.cc/150?img=15',
        },
        farmName: 'Martin Farms',
        contactNumber: '555-1975',
        status: 'PROSPECT',
    },
    {
        grower: {
            firstName: 'Nick',
            lastName: 'Thompson',
            avatar: 'https://i.pravatar.cc/150?img=16',
        },
        farmName: 'Thompson Gardens',
        contactNumber: '555-0864',
        status: 'SUSPENDED',
    },
    {
        grower: {
            firstName: 'Olivia',
            lastName: 'Garcia',
            avatar: 'https://i.pravatar.cc/150?img=17',
        },
        farmName: 'Garcia Vineyards',
        contactNumber: '555-9753',
        status: 'ACTIVE',
    },
    {
        grower: {
            firstName: 'Paul',
            lastName: 'Martinez',
            avatar: 'https://i.pravatar.cc/150?img=18',
        },
        farmName: 'Martinez Farms',
        contactNumber: '555-8642',
        status: 'INACTIVE',
    },
    {
        grower: {
            firstName: 'Quincy',
            lastName: 'Robinson',
            avatar: 'https://i.pravatar.cc/150?img=19',
        },
        farmName: 'Robinson Fields',
        contactNumber: '555-7531',
        status: 'PROSPECT',
    },
    {
        grower: {
            firstName: 'Rachel',
            lastName: 'Clark',
            avatar: 'https://i.pravatar.cc/150?img=20',
        },
        farmName: 'Clark Farms',
        contactNumber: '555-6420',
        status: 'SUSPENDED',
    },
    {
        grower: {
            firstName: 'Sam',
            lastName: 'Rodriguez',
            avatar: 'https://i.pravatar.cc/150?img=21',
        },
        farmName: 'Rodriguez Orchards',
        contactNumber: '555-5319',
        status: 'ACTIVE',
    },
    {
        grower: {
            firstName: 'Tina',
            lastName: 'Lewis',
            avatar: 'https://i.pravatar.cc/150?img=22',
        },
        farmName: 'Lewis Fields',
        contactNumber: '555-4208',
        status: 'INACTIVE',
    },
    {
        grower: {
            firstName: 'Uma',
            lastName: 'Lee',
            avatar: 'https://i.pravatar.cc/150?img=23',
        },
        farmName: 'Lee Farms',
        contactNumber: '555-3197',
        status: 'PROSPECT',
    },
    {
        grower: {
            firstName: 'Victor',
            lastName: 'Walker',
            avatar: 'https://i.pravatar.cc/150?img=24',
        },
        farmName: 'Walker Gardens',
        contactNumber: '555-2086',
        status: 'SUSPENDED',
    },
    {
        grower: {
            firstName: 'Wendy',
            lastName: 'Hall',
            avatar: 'https://i.pravatar.cc/150?img=25',
        },
        farmName: 'Hall Vineyards',
        contactNumber: '555-1975',
        status: 'ACTIVE',
    },
];
