import React from 'react';
import UserAvatar from '../../../design/Avatar/UserAvatar';
import { Box, Typography } from '@mui/material';
import PersonalInfoWithLabel from '../../../reusable/ReadOnlyFields/PersonalInfoWithLabel';
import { dateTimeConverterFromTimestamp } from '../../../utils/dateFormat';

export interface MetaData {
    title?: string;
    name: string;
    date: string;
    views?: number;
}
export default function PostMetaData({ title, name, views, date }: MetaData) {
    return (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
            {title && (
                <Box width={'100%'}>
                    <Typography
                        sx={{ wordBreak: 'break-all' }}
                        variant="font20"
                        fontWeight="bold"
                        color="text.primary">
                        {title}
                    </Typography>
                </Box>
            )}
            <Box display="flex" justifyContent={'space-between'}>
                <Box display="flex" gap={2}>
                    <UserAvatar name={name} />
                    <Box pt={1}>
                        <Box display={'flex'} gap={1}>
                            <Typography variant="font12">
                                From: {name}
                            </Typography>
                            <Typography variant="font12" color="text.neutral6">
                                {dateTimeConverterFromTimestamp(date)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {views && (
                    <PersonalInfoWithLabel
                        type={'views'}
                        text={`${views} Watchers`}
                    />
                )}
            </Box>
        </Box>
    );
}
