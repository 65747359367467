import { Typography, useTheme } from '@mui/material';

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';

export default function RowData({
    title,
    value,
}: {
    title: string;
    value: string;
}) {
    const theme = useTheme();
    const { classes } = useStyles(theme);

    return (
        <div className={classes.rowDataWrapper}>
            <Typography className={classes.rowDataName}>{title}</Typography>
            <Typography variant={'font16'} fontWeight="bold">
                {value}
            </Typography>
        </div>
    );
}

const useStyles = makeStyles<Theme>()((theme) => ({
    rowDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: theme.spacing(1.5, 0),
    },
    rowDataName: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.neutral.neutral6,
    },
}));
