import { OAKENApi } from '../OAKENApi';
import { ENDPOINTS } from '../endpoints';
import { HTTP_METHOD } from '../auth/http';
import { LEASE_NOTES } from '../tags';
import { getListProvidesTags } from '../generic';

export interface ArchivedLeaseNote {
    id: string;
    note: string;
    communicationContainer?: {
        communicationType: string;
        actualContactDate: number;
    };
    updatedAt: number;
    createdAt: number;
    archivedBy: {
        id: string;
        firstName: string;
        lastName: string;
    };
}

export const leaseNotesAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getLeasNotes: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.notes.root +
                        `/${params.leaseId}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: [LEASE_NOTES.LEASE_NOTES],
        }),
        uploadLeaseNotes: builder.mutation({
            query: ({ leaseId, note, noteFile }) => {
                const formData = new FormData();
                formData.append('note', JSON.stringify({ ...note }));
                noteFile && formData.append('noteFile', noteFile);

                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.notes.root +
                        `/${leaseId}`,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [LEASE_NOTES.LEASE_NOTES],
        }),

        pinLeaseNote: builder.mutation({
            query: ({ id }) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.notes.pin +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [LEASE_NOTES.LEASE_NOTES],
        }),
        unpinLeaseNote: builder.mutation({
            query: ({ id }) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.notes.unpin +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [LEASE_NOTES.LEASE_NOTES],
        }),
        archiveLeaseNote: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.notes.archive +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [LEASE_NOTES.LEASE_NOTES],
        }),
        getArchivedLeaseNotes: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.notes.archive,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: ArchivedLeaseNote[],
                meta,
            ): { data: ArchivedLeaseNote[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, LEASE_NOTES.LEASE_NOTES),
        }),

        restoreLeaseNote: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.notes.restore +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [LEASE_NOTES.LEASE_NOTES],
        }),
        deleteLeaseNote: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.notes.root +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: [LEASE_NOTES.LEASE_NOTES],
        }),
    }),
});

export const {
    useGetLeasNotesQuery,
    useUploadLeaseNotesMutation,
    usePinLeaseNoteMutation,
    useUnpinLeaseNoteMutation,
    useArchiveLeaseNoteMutation,
    useRestoreLeaseNoteMutation,
    useGetArchivedLeaseNotesQuery,
    useDeleteLeaseNoteMutation,
} = leaseNotesAPI;
