import {
    CONTACT_NOTES,
    CONTACTS,
    DASHBOARD,
    LAND_PARTNERS,
    NOTIFICATIONS,
    ORGANIZATIONS,
    REDUX_CACHE,
} from '../tags';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { getListProvidesTags } from '../generic';
import { LandPartnerUpdate } from '../../features/ViewLandownersDetails/LandPartnerDetails';
import { Option } from '../../features/CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { LandPartnerHistory } from '../../features/ViewLPContactHistory/useLPContactHistoryTable';
import { LandPartnerArchiveResponse } from '../../pages/Settings/ArchiveObjects/LandPartners/useTableArchiveLPConfig';
import { LandPartnerTableView } from '../../pages/LandPartners/LandPartnersTable';

export const landPartnersAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getLandPartners: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: LandPartnerTableView[],
                meta,
            ): { data: LandPartnerTableView[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, LAND_PARTNERS.LAND_PARTNERS),
        }),
        getLandPartnersShort: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.root +
                        '/short',
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: LandPartnerUpdate[],
                meta,
            ): { data: LandPartnerUpdate[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, LAND_PARTNERS.LAND_PARTNERS),
        }),
        getOneLandPartner: builder.query<LandPartnerUpdate, string | undefined>(
            {
                query: (id) => {
                    return {
                        url:
                            process.env.REACT_APP_USER_API_HOST +
                            ENDPOINTS.landpartners.root +
                            `/${id}`,
                        method: HTTP_METHOD.GET,
                    };
                },
                providesTags: (result, error, arg) => [
                    { type: LAND_PARTNERS.LAND_PARTNERS, id: arg },
                    { type: LAND_PARTNERS.LAND_PARTNERS, id: REDUX_CACHE.LIST },
                ],
            },
        ),
        getLastOutreach: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.users.lastOutreach,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: () => [{ type: CONTACT_NOTES.CONTACT_NOTES }],
        }),
        getLandPartnersSummary: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.summary,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: () => [{ type: DASHBOARD.DASHBOARD }],
        }),
        getNotificationsSummary: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.landpartners.notificationsSummary,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        addLandPartner: builder.mutation({
            query: ({ landPartnerLogo, ...values }) => {
                const formData = new FormData();
                if (landPartnerLogo) {
                    formData.append('landPartnerLogo', landPartnerLogo[0]);
                }
                formData.append('request', JSON.stringify(values));
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.root,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [
                LAND_PARTNERS.LAND_PARTNERS,
                ORGANIZATIONS.ORGANIZATIONS,
                DASHBOARD.DASHBOARD,
            ],
        }),
        updateLandPartner: builder.mutation({
            query: ({ landPartnerLogo, ...values }) => {
                const formData = new FormData();
                if (landPartnerLogo) {
                    formData.append('landPartnerLogo', landPartnerLogo[0]);
                }
                formData.append('request', JSON.stringify(values));
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.root +
                        `/${values.id}`,
                    method: HTTP_METHOD.PUT,
                    body: formData,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: LAND_PARTNERS.LAND_PARTNERS, id: REDUX_CACHE.LIST },
                { type: LAND_PARTNERS.LAND_PARTNERS, id: arg },
                { type: DASHBOARD.DASHBOARD, id: REDUX_CACHE.LIST },
            ],
        }),
        addOrganizationLogo: builder.mutation({
            query: (payload) => {
                const formData = new FormData();
                if (payload.attachment) {
                    formData.append('companyLogo', payload.attachment);
                }
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.logo +
                        `/${payload.id}`,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [ORGANIZATIONS.ORGANIZATIONS],
        }),
        landPartnersList: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.user,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: Option[],
                meta,
            ): { data: Option[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, LAND_PARTNERS.LAND_PARTNERS),
        }),
        assignActivity: builder.mutation({
            query: ({ id, ...payload }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.activity +
                        `/${id}/land-partner`,
                    method: HTTP_METHOD.POST,
                    body: payload,
                };
            },
            invalidatesTags: [NOTIFICATIONS.NOTIFICATIONS],
        }),
        landPartnersHistory: builder.query({
            query: ({ id, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.history +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: LandPartnerHistory[],
                meta,
            ): { data: LandPartnerHistory[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, LAND_PARTNERS.LAND_PARTNERS),
        }),
        changePrimaryContact: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.root +
                        `/${payload.landPartnerId}/change-primary-contact`,
                    method: HTTP_METHOD.POST,
                    params: {
                        newPrimaryContactId: payload.newPrimaryContactId,
                    },
                };
            },
            invalidatesTags: [CONTACTS.CONTACTS, LAND_PARTNERS.LAND_PARTNERS],
        }),
        getLandPartnersArchive: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.archive.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: LandPartnerArchiveResponse[],
                meta,
            ): { data: LandPartnerArchiveResponse[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, LAND_PARTNERS.LAND_PARTNERS),
        }),
        archiveLandPartner: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.archive.root +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
        }),
        restoreLandPartner: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.archive.restore +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [LAND_PARTNERS.LAND_PARTNERS],
        }),
        deleteLandPartner: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.archive.root +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: [LAND_PARTNERS.LAND_PARTNERS],
        }),
        getLandPartnersContacts: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        `${ENDPOINTS.landpartners.root}/${params.id}/contacts`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },

            providesTags: (result) =>
                getListProvidesTags(result?.data, LAND_PARTNERS.LAND_PARTNERS),
        }),

        updateLabels: builder.mutation({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.relationshipTree.labels,
                    method: HTTP_METHOD.PUT,
                    body: payload,
                };
            },
            invalidatesTags: [CONTACTS.CONTACTS],
        }),
        searchLP: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.landpartners.search,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            providesTags: [LAND_PARTNERS.LAND_PARTNERS],
        }),
    }),
});

export const {
    useLazySearchLPQuery,
    useUpdateLabelsMutation,
    useGetLandPartnersContactsQuery,
    useDeleteLandPartnerMutation,
    useLandPartnersHistoryQuery,
    useGetLandPartnersArchiveQuery,
    useArchiveLandPartnerMutation,
    useRestoreLandPartnerMutation,
    useGetLandPartnersSummaryQuery,
    useLandPartnersListQuery,
    useAssignActivityMutation,
    useGetNotificationsSummaryQuery,
    useAddLandPartnerMutation,
    useGetLandPartnersQuery,
    useGetLandPartnersShortQuery,
    useGetLastOutreachQuery,
    useLazyGetLastOutreachQuery,
    useLazyGetLandPartnersQuery,
    useGetOneLandPartnerQuery,
    useLazyGetOneLandPartnerQuery,
    useUpdateLandPartnerMutation,
    useAddOrganizationLogoMutation,
    useChangePrimaryContactMutation,
} = landPartnersAPI;
