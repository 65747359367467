import useGrowersTable from './useGrowersTable';
import GenericTable from '../../design/GenericTable/GenericTable';
export default function GrowersTable() {
    const { totalCount, data, tableConfig } = useGrowersTable();
    return (
        <div>
            <GenericTable
                data={data}
                tableConfig={tableConfig}
                totalCount={totalCount}
            />
        </div>
    );
}
