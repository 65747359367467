import React from 'react';

import { Box, Grid, Skeleton, Typography } from '@mui/material';
import theme from '../../styles/theme';
import { Lease } from './TableConfig';
import { format } from 'date-fns';
import { useGetLeaseDocumentsQuery } from '../../api/leases/leaseAPI';
import LeaseFileWithDownloadButton from './LeaseFileWithDownloadButton';
import { Document } from '../../features/ViewLeaseDetails/LeaseDetails';
import { DATE_FORMAT } from '../../utils/dateFormat';

interface ExpandComponentProps {
    data: Lease;
}

export default function ExpandComponent({ data }: ExpandComponentProps) {
    const { data: documents, isLoading: isDocumentLoading } =
        useGetLeaseDocumentsQuery(data.id);
    if (isDocumentLoading)
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}>
                <Skeleton animation="wave" width="80%" />
                <Skeleton animation="wave" width="80%" />
                <Skeleton animation="wave" width="80%" />
            </Box>
        );
    return (
        <Grid
            container
            justifyContent={'space-between'}
            width={'100%'}
            sx={{
                padding: theme.spacing(2, 3),
                backgroundColor: theme.palette.backgroundB.backgroundB2,
            }}>
            <Grid item display={'flex'} flexDirection={'column'}>
                <Typography
                    variant={'font14'}
                    fontWeight={'medium'}
                    color={'text.secondary'}>
                    Lease
                </Typography>

                <Typography variant={'font14'} color={'text.neutral6'}>
                    {data.type}
                </Typography>
            </Grid>
            <Grid item display={'flex'} flexDirection={'column'}>
                <Typography
                    variant={'font14'}
                    fontWeight={'medium'}
                    color={'text.secondary'}>
                    Payment Schedule
                </Typography>
                {!!data.paymentSchedule.length && (
                    <Typography variant={'font14'} color={'text.neutral6'}>
                        {data.paymentSchedule?.join(', ')}
                    </Typography>
                )}
            </Grid>
            <Grid item display={'flex'} flexDirection={'column'}>
                <Typography
                    variant={'font14'}
                    fontWeight={'medium'}
                    color={'text.secondary'}>
                    Land Partners
                </Typography>
                {!!data.landowners.length && (
                    <Typography variant={'font14'} color={'text.neutral6'}>
                        {data.landowners.map((i) => i.name).join(', ')}
                    </Typography>
                )}
            </Grid>
            <Grid item display={'flex'} flexDirection={'column'}>
                <Typography
                    variant={'font14'}
                    fontWeight={'medium'}
                    color={'text.secondary'}>
                    Available Downloads
                </Typography>
                {documents.map((file: Document, index: number) => (
                    <LeaseFileWithDownloadButton
                        key={index}
                        id={file.id}
                        name={file.fileName}
                        date={format(file.createdAt * 1000, DATE_FORMAT)}
                    />
                ))}
            </Grid>
        </Grid>
    );
}
