import React from 'react';

import { CommunicationContainer } from '../../reusable/NotesField/NotesField';

import {
    useGetLeasNotesQuery,
    useUploadLeaseNotesMutation,
} from '../../api/leases/leaseNotesAPI';

import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import Chat from '../../reusable/Chat/Chat';

import { NoteDetailsProps } from '../../reusable/Chat/NoteDetails';

import { dateTimeConverterFromTimestamp } from '../../utils/dateFormat';

export interface LeaseNoteProps {
    id: string;
    createdBy: string;
    updatedBy: string;
    createdAt: string;
    updatedAt: string;
    pinned: boolean;
    leaseId: string;
    note: string;
    document: Document[];
    createdByName: string;
    communicationContainer: CommunicationContainer;
}

export interface Document {
    id: string;
    createdBy: string;
    updatedBy: string;
    createdAt: string;
    updatedAt: string;
    parentId: string;
    fileLocation: string;
    fileSize: number;
    fileName: string;
    documentType: string;
}

interface OwnProps {
    id?: string;
}

export default function LeaseNotes({ id }: OwnProps) {
    const { data: notes, isLoading: isLoadingNotes } = useGetLeasNotesQuery({
        sort: `createdAt,desc`,
        leaseId: id,
    });
    const { handleSubmit: addNote, isLoading: isAddingNote } =
        useHandleSubmitRTK({
            useRtkHook: useUploadLeaseNotesMutation,
            successMessage: 'Note added successfully',
        });

    function handleAddNote(
        note: string,
        file: File | null,
        communicationContainer: CommunicationContainer,
    ) {
        const submitValue = {
            leaseId: id,
            note: { note, communicationContainer },
            noteFile: file,
        };
        addNote(submitValue);
    }

    return (
        <Chat
            addNote={handleAddNote}
            cards={convertResponseToNote(notes)}
            isLoadingNotes={isLoadingNotes}
            isAddingNote={isAddingNote}
            type={'LEASE'}
            autoFocus={false}
        />
    );
}

function convertResponseToNote(response: LeaseNoteProps[]): NoteDetailsProps[] {
    return response?.map((note) => ({
        id: note.id,
        pinned: note.pinned,
        content: note.note,
        name: note.createdByName,
        actualContactDate: note.communicationContainer?.actualContactDate,
        communicationType: note.communicationContainer?.communicationType,
        createdAt: dateTimeConverterFromTimestamp(note.createdAt),
        updatedAt: dateTimeConverterFromTimestamp(note.updatedAt),
        attachment: note.document.length
            ? {
                  id: note.document[0]?.id,
                  date: dateTimeConverterFromTimestamp(
                      note.document[0]?.createdAt,
                  ),
                  name: note.document[0]?.fileName,
                  size: note.document[0]?.fileSize,
                  link: note.document[0]?.fileLocation,
              }
            : undefined,
    }));
}
