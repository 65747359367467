import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { PreferredContactMethodOptions } from '../features/CreateContact/FormConfigs/contactDetailsConfig';
import { PhoneType } from '../reusable/FormUI/PhoneNumberField';

interface ReturnProps {
    preferredContactMethodList: PreferredContactMethodOptions[];
}

export function useDynamicPreferredContactMethod(
    propertyName?: string,
): ReturnProps {
    const form = useForm();
    const { values } = form.getState();

    const initialList: PreferredContactMethodOptions[] = [
        { id: 'PRIMARY_EMAIL', name: 'Primary Email' },
        { id: 'SECONDARY_EMAIL', name: 'Secondary Email' },
        { id: 'CELL_PHONE', name: 'Cell Phone' },
        { id: 'OFFICE_PHONE', name: 'Office Phone' },
        { id: 'HOME_PHONE', name: 'Home Phone' },
        { id: 'FAX', name: 'Fax' },
    ];

    const [preferredContactMethodList, setPreferredContactMethodList] =
        useState<PreferredContactMethodOptions[]>(initialList);

    const updatePreferredContactMethodList = useCallback(() => {
        const list: PreferredContactMethodOptions[] = [
            { id: 'PRIMARY_EMAIL', name: 'Primary Email' },
            { id: 'SECONDARY_EMAIL', name: 'Secondary Email' },
        ];

        const contactNumbers = propertyName
            ? getValueByObjectPath(values, propertyName, 'contactNumbers')
            : values?.contactNumbers;

        if (contactNumbers?.length && contactNumbers.length > 0) {
            contactNumbers?.forEach((contactNumber: PhoneType) => {
                switch (contactNumber?.type) {
                    case 'CELL_PHONE':
                        list.push({ id: 'CELL_PHONE', name: 'Cell Phone' });
                        break;
                    case 'OFFICE_PHONE':
                        list.push({ id: 'OFFICE_PHONE', name: 'Office Phone' });
                        break;
                    case 'HOME_PHONE':
                        list.push({ id: 'HOME_PHONE', name: 'Home Phone' });
                        break;
                    case 'FAX':
                        list.push({ id: 'FAX', name: 'Fax' });
                        break;
                    default:
                        break;
                }
            });
        }
        setPreferredContactMethodList(list);
    }, [values]);

    useEffect(() => {
        updatePreferredContactMethodList();
    }, [updatePreferredContactMethodList]);

    useEffect(() => {
        if (!preferredContactMethodList.length) {
            form.change('preferredContactMethod', null);
        }
        const preferredContactMethod = propertyName
            ? getValueByObjectPath(
                  values,
                  propertyName,
                  'preferredContactMethod',
              )
            : values?.preferredContactMethod;
        if (
            preferredContactMethod &&
            !preferredContactMethodList.find(
                (item) => item.id === preferredContactMethod,
            )
        ) {
            form.change('preferredContactMethod', null);
        }
    }, [preferredContactMethodList, form]);

    return {
        preferredContactMethodList,
    };
}

function getValueByObjectPath(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    obj,
    firstPartOfPath: string,
    lastPartOfPath: string,
): string | undefined {
    const keys = firstPartOfPath.split(/[\\.[\]]/).filter((key) => key);
    let result = obj;

    for (const key of keys) {
        const normalizedKey: string | number = /^\d+$/.test(key)
            ? parseInt(key, 10)
            : key;

        if (result[normalizedKey] !== undefined) {
            result = result[normalizedKey];
        } else {
            return undefined;
        }
    }

    return result[lastPartOfPath] !== undefined
        ? result[lastPartOfPath]
        : undefined;
}
