import {
    CropShareDistribution,
    Lease,
} from '../../pages/LandPortfolio/TableConfig';
import { Expenses } from '../CreateLeaseForm/ExpensesDetailForm';
import BaseBarChart, {
    BarChartConfig,
    DataEntry,
} from '../../design/Charts/BarChart/BaseBarChart';
import { Grid } from '@mui/material';
import theme from '../../styles/theme';

interface OwnProps {
    data: Lease;
}
export default function CropSharingCharts({ data }: OwnProps) {
    const { cropShareDistributions = [], expenses = [] } = data;

    return (
        <Grid container spacing={2}>
            <BaseBarChart
                data={getExpensesChartData(expenses)}
                config={expensesChartConfig(
                    expenses?.length === 0
                        ? { isHidden: true }
                        : { isHidden: false },
                )}
            />

            <BaseBarChart
                data={getCropShareChartData(cropShareDistributions)}
                config={cropShareChartConfig(
                    cropShareDistributions?.length === 0
                        ? { isHidden: true }
                        : { isHidden: false },
                )}
            />
        </Grid>
    );
}

function getExpensesChartData(expenses: Expenses[]): DataEntry[] {
    return (
        expenses &&
        expenses.map((expense) => ({
            name: expense.description,
            ['Grower']: parseFloat(expense?.growerSharePercent as string),
            ['Landowner']: parseFloat(expense?.landownerSharePercent as string),
        }))
    );
}
function getCropShareChartData(
    cropShareDistributions: CropShareDistribution[],
): DataEntry[] {
    return (
        cropShareDistributions &&
        cropShareDistributions.map((cropShare) => ({
            name: cropShare.crop,
            ['Grower']: cropShare.growerSharePercent,
            ['Landowner']: cropShare.landownerSharePercent,
        }))
    );
}
function cropShareChartConfig({
    isHidden,
}: {
    isHidden: boolean;
}): BarChartConfig<string> {
    return {
        isHidden,
        showLegend: true,
        showTooltip: true,
        barChartOptions: {
            width: 500,
            height: 300,
        },
        maxBarSize: 85,
        isStacked: true,
        bars: ['Grower', 'Landowner'],
        colors: {
            Grower: theme.palette.limeGreen.limeGreenShade2,
            Landowner: theme.palette.yellowGreen.yellowGreenShade2,
        },
        chartTitle: 'Crop Sharing',
    };
}
function expensesChartConfig({
    isHidden,
}: {
    isHidden: boolean;
}): BarChartConfig<string> {
    return {
        isHidden,
        showLegend: true,
        showTooltip: true,
        barChartOptions: {
            width: 500,
            height: 300,
        },
        maxBarSize: 85,
        isStacked: true,
        bars: ['Grower', 'Landowner'],
        colors: {
            Grower: theme.palette.limeGreen.limeGreenShade2,
            Landowner: theme.palette.yellowGreen.yellowGreenShade2,
        },
        chartTitle: 'Expenses',
    };
}
