import customToastWithAlert from '../design/CustomToastWithAlert';
import { useCallback, useEffect } from 'react';

interface OwnProps {
    useRtkHook: //eslint-disable-next-line @typescript-eslint/no-explicit-any
    any;
    onSuccess?: () => void;
    onError?: () => void;
    successMessage?: string;
    isShowSuccessToast?: boolean;
    isShowErrorToast?: boolean;
}

interface errorAPI {
    data: {
        description: string;
    };
}
export default function useHandleSubmitRTK({
    useRtkHook,
    onSuccess,
    onError,
    successMessage,
    isShowSuccessToast = true,
    isShowErrorToast = true,
}: OwnProps) {
    const [apiCall, { data, isLoading, isSuccess, isError, error, reset }] =
        useRtkHook();
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = useCallback((values: any) => {
        apiCall(values);
    }, []);

    useEffect(() => {
        if (isSuccess) {
            isShowSuccessToast &&
                customToastWithAlert({
                    type: 'success',
                    message: successMessage || 'Success',
                });
            onSuccess && onSuccess();
        }
        if (isError) {
            isShowErrorToast &&
                customToastWithAlert({
                    type: 'error',
                    message:
                        (error as errorAPI)?.data?.description ||
                        'Something went wrong',
                });
            onError && onError();
        }
    }, [isSuccess, isError]);

    return { handleSubmit, data, isLoading, isSuccess, isError, reset, error };
}
