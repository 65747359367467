import { Button, Typography } from '@mui/material';
import * as yup from 'yup';
import React from 'react';
import { useFormik } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom';

import useStyles from './LogIn.styles';
import theme from '../../../styles/theme';
import FormTextField, {
    EndIconType,
} from '../../../design/FormUI/FormTextField';
import CheckboxField from '../../../design/FormUI/CheckboxField';
import PATHS from '../../../router/paths';
import { emailRegex } from '../../../constant/regex';
import {
    clearLocalStorage,
    clearSessionStorage,
} from '../../../utils/localStorageUtils';
import {
    REFRESH_TOKEN_KEY,
    TOKEN_KEY,
} from '../../../constant/localStorageKeys';
import { useLoginMutation } from '../../../api/auth/authAPI';
import customToastWithAlert from '../../../design/CustomToastWithAlert';
import { useDispatch } from 'react-redux';
import { OAKENApi } from '../../../api/OAKENApi';
import mixpanel from "../../../mixpanel";

const INITIAL_FORM_STATE = {
    email: '',
    password: '',
    rememberMe: false,
};

export const FORM_VALIDATION = yup.object({
    email: yup
        .string()
        .matches(emailRegex, '* Please provide a correct email address')
        .required('* This field is required'),
    password: yup.string().required('* This field is required'),
});

interface ILogInForm {
    passwordReset?: boolean;
}
interface SubmitValue {
    email: string;
    password: string;
}
export default function LogInForm(props: ILogInForm) {
    const { passwordReset } = props;
    const { classes } = useStyles(theme);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [login] = useLoginMutation();

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: { ...INITIAL_FORM_STATE },
        validationSchema: FORM_VALIDATION,
        onSubmit,
    });

    function onSubmit(values: SubmitValue) {
        clearLocalStorage();
        clearSessionStorage();

        mixpanel.track('Sign Up', {
          'user': values.email
        });

        login({
            email: values.email,
            password: values.password,
        })
            .unwrap()
            .then((data) => {
                dispatch(OAKENApi.util?.resetApiState());
                localStorage.setItem(TOKEN_KEY, data.accessToken);
                localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken);
                navigate(PATHS.dashboard);
            })
            .catch((error) => {
                formik.resetForm();
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    }

    return (
        <div className={classes.formWrapper}>
            <Typography
                className={classes.title}
                variant="font32"
                fontWeight="regular">
                Welcome back
            </Typography>
            <Typography className={classes.details}>
                {passwordReset
                    ? 'Your password has been successfully reset. Please log-in below.'
                    : 'Please fill in your details to login'}
            </Typography>
            <form onSubmit={formik.handleSubmit} noValidate>
                <FormTextField
                    name={'email'}
                    label={'Email Address'}
                    required
                    className={classes.textField}
                    formik={formik}
                />
                <FormTextField
                    name={'password'}
                    required
                    label={'Password'}
                    formik={formik}
                    endIconType={EndIconType.password}
                />

                <div className={classes.bottomContainer}>
                    <NavLink
                        to={`/${PATHS.auth}/${PATHS.forgotPassword}`}
                        className={classes.link}>
                        Forgot Password?
                    </NavLink>
                    <CheckboxField
                        text={'Remember Me'}
                        name={'rememberMe'}
                        formik={formik}
                    />
                </div>
                <div className={classes.bottomContainer}>
                    <Button
                        variant={'contained'}
                        className={classes.button}
                        type="submit"
                        size={'large'}>
                        Log In
                    </Button>
                </div>
            </form>
        </div>
    );
}
