import { Box, Button } from '@mui/material';
import React from 'react';
import { ReactComponent as PinNoteIcon } from '../../assets/icons/pin_note_icon.svg';
import { ReactComponent as UnpinNoteIcon } from '../../assets/icons/unpin_note_icon.svg';
import theme from '../../styles/theme';

interface OwnProps {
    id: string;
    pinned: boolean;
    handlePinNote: (id: string) => void;
    handleUnpinNote: (id: string) => void;
}

export default function PinControls({
    id,
    pinned,
    handlePinNote,
    handleUnpinNote,
}: OwnProps) {
    const handleClick = pinned ? handleUnpinNote : handlePinNote;
    const Icon = pinned ? UnpinNoteIcon : PinNoteIcon;
    const text = pinned ? 'Unpin' : 'Pin';

    return (
        <Box display={'flex'} width={'100%'}>
            <Button
                endIcon={<Icon />}
                onClick={() => handleClick(id)}
                sx={{
                    fontSize: 12,
                    color: theme.palette.brandLightTurq,
                    textTransform: 'capitalize',
                }}>
                {text}
            </Button>
        </Box>
    );
}
