import { Button, Typography } from '@mui/material';

import theme from '../../../../styles/theme';
import useStyles from './AllOrganizations.styles';

import OrganizationsList from './OrganizationsList';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../../../router/paths';

export default function AllOrganizations() {
    const { classes } = useStyles(theme);
    const navigate = useNavigate();
    return (
        <div className={classes.allOrganizationsWrapper}>
            <Typography variant={'font16'}>
                Modify access based on your teams roles, or modify permissions
                for specific users. [additional descriptive text to be
                determined]
            </Typography>
            <div className={classes.controlPanel}>
                <Button
                    onClick={() => navigate(`${PATHS.create}`)}
                    color={'secondary'}
                    size={'medium'}>
                    Add Organization +
                </Button>
            </div>
            <OrganizationsList />
        </div>
    );
}
