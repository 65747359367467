import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';

export const useStyles = makeStyles<Theme>()((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: theme.spacing(4),
    },
    wrapperHistory: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        flex: 1,
        maxHeight: '60vh',
        overflowY: 'auto',
        width: '100%',
        padding: theme.spacing(1, 2),
        borderBottom: `solid 1px ${theme.palette.strokeS1}`,
    },
    wrapperHistoryView: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        gap: theme.spacing(1),
        overflowY: 'auto',
        width: '100%',
        padding: theme.spacing(1, 2),
        borderBottom: `solid 1px ${theme.palette.strokeS1}`,
    },
    mainTextWrapper: {
        paddingBottom: theme.spacing(4),
        borderBottom: `solid 1px ${theme.palette.backgroundB.backgroundB4}`,
    },
    linkersText: {
        fontSize: '14px',
        fontWeight: 500,
    },
    bodyRecepiet: {
        paddingTop: theme.spacing(4),
        borderBottom: `solid 1px ${theme.palette.backgroundB.backgroundB4}`,
    },
    totalRentAmount: {
        color: theme.palette.brandLightTurq,
        fontSize: 24,
        fontWeight: 500,
    },
    alertIcon: {
        padding: theme.spacing(3, 1),
        backgroundColor: theme.palette.lightTurq.lightTurqTint9,
        color: theme.palette.lightTurq.lightTurqTint1,
    },
}));
