import { AutocompleteOption } from './AutocompleteField';
import { Autocomplete as MuiAutocomplete, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';

export interface SearchAutocomplete {
    onChange: (value: AutocompleteOption | string | null) => void;
    placeholder?: string;
    isDisabled?: boolean;
    errorText?: string;
    name?: string;
    size?: 'small' | 'medium';
    freeSolo?: boolean;
    value?: AutocompleteOption | string | null;
    options?: AutocompleteOption[];
}
export default function SearchAutocompleteField({
    value,
    size,
    errorText,
    onChange,
    placeholder,
    isDisabled,
    name,
    freeSolo = false,
    options = [],
}: SearchAutocomplete) {
    return (
        <FormControl
            variant="outlined"
            size={size}
            error={!!errorText}
            disabled={isDisabled}>
            <MuiAutocomplete
                id={name}
                freeSolo={freeSolo}
                disabled={isDisabled}
                autoSelect
                filterSelectedOptions
                getOptionKey={(option: string | AutocompleteOption) =>
                    typeof option === 'string' ? option : option.id
                }
                options={options.map((option) => {
                    return {
                        id: option.id,
                        name: option.name,
                    };
                })}
                getOptionLabel={(option: AutocompleteOption | string) =>
                    typeof option === 'string' ? option : option.name
                }
                onChange={(_, value: AutocompleteOption | string | null) => {
                    onChange(value);
                }}
                value={value || null}
                isOptionEqualToValue={(option, value) =>
                    typeof option === 'string'
                        ? option === value
                        : option.id ===
                          (typeof value === 'string' ? value : value.id)
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={placeholder}
                        variant="outlined"
                        size={size}
                        error={!!errorText}
                        helperText={errorText}
                    />
                )}
            />
        </FormControl>
    );
}
