import { Button, ButtonGroup } from '@mui/material';
import {
    DocumentTypeContext,
    RequestDocumentType,
} from './DocumentTypeContext';
import { useContext } from 'react';

interface FilterButton {
    name: RequestDocumentType;
    label: string;
}

export default function FilterList() {
    const { type, setType } = useContext(DocumentTypeContext);

    return (
        <ButtonGroup>
            {ButtonConfig.map((button) => (
                <Button
                    key={button.name}
                    onClick={() => setType(button.name)}
                    sx={{
                        textDecoration:
                            type === button.name ? 'underline' : 'none',
                    }}
                    variant={'text'}>
                    {button.label}
                </Button>
            ))}
        </ButtonGroup>
    );
}

const ButtonConfig: FilterButton[] = [
    {
        name: '',
        label: 'All',
    },
    {
        name: 'LEASE',
        label: 'Lease',
    },
    {
        name: 'FINANCIAL',
        label: 'Financial',
    },
    {
        name: 'FILINGS',
        label: 'Filings',
    },
    {
        name: 'TASK',
        label: 'Task',
    },
    {
        name: 'REPORTS',
        label: 'Reports',
    },
    {
        name: 'OTHER',
        label: 'Other',
    },
];
