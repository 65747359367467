import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import { Box } from '@mui/material';
import UserAvatar from '../../design/Avatar/UserAvatar';
import LinkText from '../../design/Fields/LinkText';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import { mockData } from './utils';
export interface GrowersTable {
    grower: Grower;
    farmName: string;
    contactNumber: string;
    status: GrowerStatus;
}
interface Grower {
    firstName: string;
    lastName: string;
    avatar: string;
}
type GrowerStatus = 'ACTIVE' | 'INACTIVE' | 'PROSPECT' | 'SUSPENDED';
export default function useGrowersTable() {
    const { sortKey, sortOrder, handleSort } = useSorting('grower', 'asc');
    const { handlePageChange, handleItemsPerPageChange } = usePagination(1, 25);
    const columns: TableColumn<GrowersTable>[] = [
        {
            header: {
                label: 'Grower Name',
                sortable: true,
                sorting: {
                    id: 'grower',
                    direction: sortKey === 'grower' ? sortOrder : 'desc',
                    isSorted: sortKey === 'grower',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <UserAvatar
                        name={`${data.grower.firstName} ${data.grower.lastName}`}
                        avatar={data.grower.avatar}
                        size={'small'}
                    />
                    <LinkText
                        to={`/}`}
                        text={`${data.grower.firstName} ${data.grower.lastName}`}
                        variant={'font14'}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Farm Name',
                sortable: true,
                sorting: {
                    id: 'farmName',
                    direction: sortKey === 'farmName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'farmName',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.farmName}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Contact No.',
                sortable: false,
                sorting: {
                    id: 'contactNumber',
                    direction: sortKey === 'contactNumber' ? sortOrder : 'desc',
                    isSorted: sortKey === 'contactNumber',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.contactNumber}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                customizeKey: 'status',
                label: 'Status',
                sortable: false,
                sorting: {
                    id: 'status',
                    direction: sortKey === 'status' ? sortOrder : 'desc',
                    isSorted: sortKey === 'status',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <StatusTag {...statusMap[data?.status]} />,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: 25,
        initialPage: 1,
        expandable: false,
    };
    const totalCount = mockData?.length || 0;
    return { data: mockData || [], totalCount, tableConfig };
}
const statusMap: Record<GrowerStatus, Tag> = {
    ACTIVE: {
        status: 'success',
        text: 'Active',
    },
    INACTIVE: {
        status: 'disabled',
        text: 'Inactive',
    },
    PROSPECT: {
        status: 'warning',
        text: 'Prospect',
    },
    SUSPENDED: {
        status: 'error',
        text: 'Do not contact',
    },
};
