import React, { useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import CopiedReadField from '../../design/CopiedReadField/CopeiedReadField';
import LeaseNotes from '../../features/ViewLeaseDetails/LeaseNotes';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useCopyLeaseMutation,
    useGetLeaseDocumentsQuery,
} from '../../api/leases/leaseAPI';
import Loader from '../../design/BaseLoader';
import { convertLeaseDetailDataToField } from './utils';
import { format } from 'date-fns';
import LeaseFileWithDownloadButton from '../../pages/LandPortfolio/LeaseFileWithDownloadButton';
import { useGetPaymentSummaryQuery } from '../../api/payment/paymentAPI';
import theme from '../../styles/theme';
import ModalBonusFieldPreview from '../CreateLeaseForm/ModalBonusFieldPreview';
import PATHS from '../../router/paths';
import UploadForm from './UploadForm';
import PaymentTable from './PaymentTable';
import { DATE_FORMAT } from '../../utils/dateFormat';
import BasedTooltip from '../../design/BasedTooltip';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import ArchivedLease from './ArchivedLease';
import CropSharingCharts from './CropSharingCharts';
import { Lease, LeaseFsaId } from '../../pages/LandPortfolio/TableConfig';
import useManagePermission from '../../hooks/useManagePermission';

export interface Document {
    id: string;
    fileName: string;
    type: string;
    createdAt: number;
    uploadedByAvatar?: string;
    createdByName: string;
}

export interface DateAmount {
    paymentDate: number;
    amount: number;
}

export interface PaymentSchedule {
    year: string;
    dateAmounts: DateAmount[];
    total: number;
}

interface OwnProps {
    data: Lease;
    isLoading: boolean;
}

export default function LeaseDetails({ data, isLoading }: OwnProps) {
    const { classes } = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    const { data: paymentsData, isLoading: isPaymentsDataLoading } =
        useGetPaymentSummaryQuery({
            leaseId: id,
        });

    const [isDeactivateModalShowing, setIsDeactivateModalShowing] =
        useState(false);

    const { data: documents, isLoading: isDocumentLoading } =
        useGetLeaseDocumentsQuery(id);
    const { checkPermission } = useManagePermission();
    const { handleSubmit: copyLease, data: copyData } = useHandleSubmitRTK({
        useRtkHook: useCopyLeaseMutation,
        onSuccess: () =>
            navigate(
                `/${PATHS.landPortfolio}/${PATHS.manage}/${PATHS.create}`,
                {
                    state: {
                        ...copyData?.draftData,
                        id: copyData.id,
                        isCopy: true,
                    },
                },
            ),
        successMessage: 'Lease copied successfully',
    });
    const actionButtonItems: ActionMenuItem[] = [
        {
            label: `Edit`,
            onClick: () =>
                navigate(`./${PATHS.update}`, {
                    state: { type: data?.type },
                }),
            hidden: !checkPermission('leases', 'modify'),
        },
        {
            label: 'Copy',
            onClick: () => copyLease({ id: id }),
            hidden: !checkPermission('leases', 'modify'),
        },
        {
            label: 'Archive',
            onClick: () => setIsDeactivateModalShowing(true),
            hidden: !checkPermission('leases', 'modify'),
        },
    ];
    if (isLoading || isDocumentLoading || isPaymentsDataLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    const fieldNameString = data?.fsaIds
        .map((i: LeaseFsaId) => i?.landName)
        .join('; ');

    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <Box
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'space-between'}>
                    <BasedTooltip text={fieldNameString}>
                        <Typography
                            noWrap
                            className={classes.titleWrapper}
                            variant="font20"
                            color="text.secondaryContrast"
                            fontWeight="medium">
                            Overview: {fieldNameString}
                        </Typography>
                    </BasedTooltip>
                    <ActionButton label={'Actions'} items={actionButtonItems} />
                </Box>
                <Grid className={classes.viewWrapper}>
                    {convertLeaseDetailDataToField(data).map(
                        ({ title, value, copied, isHidden }, index) => (
                            <CopiedReadField
                                key={index}
                                title={title}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                value={value}
                                hasCopied={copied}
                                isHidden={isHidden}
                            />
                        ),
                    )}
                    {data?.bonusCriteria && (
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                noWrap
                                variant={'font14'}
                                fontWeight="bold">
                                Bonus Calculation Description
                            </Typography>
                            <Button
                                sx={{ paddingLeft: 0 }}
                                size={'small'}
                                onClick={() => setOpenModal(true)}>
                                Show Bonus Preview
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Box>
            <CropSharingCharts data={data} />
            <Grid
                width={'100%'}
                sx={{
                    padding: theme.spacing(2, 2, 1),
                    backgroundColor: theme.palette.backgroundB.backgroundB2,
                }}>
                <Typography fontWeight="bold">
                    Rent and Payment Timing
                </Typography>
            </Grid>
            <PaymentTable paymentsData={paymentsData} />
            <LeaseNotes id={id} />
            <Box className={classes.uploadDocumentWrapper}>
                <UploadForm id={id} />
                <Box width={'50%'}>
                    <Typography
                        variant={'font12'}
                        fontWeight={'bold'}
                        textTransform={'uppercase'}
                        color={'text.secondary'}>
                        LEASE DOCUMENTS
                    </Typography>
                    {documents.map((file: Document, index: number) => (
                        <LeaseFileWithDownloadButton
                            key={index}
                            id={file.id}
                            name={file.fileName}
                            date={format(file.createdAt * 1000, DATE_FORMAT)}
                        />
                    ))}
                </Box>
            </Box>
            <ModalBonusFieldPreview
                isShowing={openModal}
                onClose={() => setOpenModal(false)}
                data={data?.bonusCriteria}
            />
            <ArchivedLease
                isShowing={isDeactivateModalShowing}
                id={id}
                onClose={() => setIsDeactivateModalShowing(false)}
            />
        </Box>
    );
}
const useStyles = makeStyles()((theme) => ({
    titleWrapper: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.neutralWhite,
    },
    viewWrapper: {
        display: 'flex',
        height: '80%',
        flexWrap: 'wrap',
        width: '100%',
        columnGap: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.neutralWhite,
    },

    uploadDocumentWrapper: {
        width: '100%',
        display: 'flex',
        columnGap: theme.spacing(2),
        padding: theme.spacing(3, 2),
        backgroundColor: theme.palette.backgroundB.backgroundB2,
        borderRadius: 12,
        rowGap: theme.spacing(2),
        flex: 1,
    },
}));
