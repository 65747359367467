import FormPage from '../../reusable/FormUI/FormPage';
import { formBillingConfig } from './formBillingConfig';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-final-form';
import { useParams } from 'react-router-dom';

export default function BillingFormPage() {
    const form = useForm();
    const { values } = form.getState();
    const { id } = useParams<{ id: string }>();
    const isEdit = !!id;
    const address = isEdit
        ? values?.primaryContact?.primaryAddress
        : values?.primaryContact?.newPrimaryContact?.addresses?.[0];

    function handleSetAddress() {
        form.change('billingAddress', address);
    }
    return (
        <Box>
            <Box
                display={'flex'}
                width={'100%'}
                columnGap={2}
                justifyContent={'flex-end'}
                pb={2}>
                <Button
                    size={'medium'}
                    disabled={!address}
                    variant={'outlined'}
                    onClick={handleSetAddress}>
                    Copy from Primary Contact
                </Button>
            </Box>
            <FormPage formConfig={formBillingConfig} />
        </Box>
    );
}
