import React, { useContext, useEffect, useState } from 'react';
import {
    DocumentTypeContext,
    RequestDocumentType,
} from './DocumentTypeContext';
import { TableColumn } from '../../../design/GenericTable/GenericTable';
import BasedTooltip from '../../../design/BasedTooltip';
import { usePagination } from '../../../design/GenericTable/useBackendPagination';
import { Box, Button, Typography } from '@mui/material';
import ProfilePhoto from '../../../reusable/ProfilePhoto';
import { dateTimeConverterFromUTC } from '../../../utils/dateFormat';

import VisionSwitch from './VisionSwitch';
import { useDownloadDocuments } from '../../../hooks/useDownloadDocuments';
import { useSelection } from '../../../design/GenericTable/useSelection';
import { useLazyDocumentsListByLPIDQuery } from '../../../api/landpartners/documentsAPI';
import { useParams } from 'react-router-dom';
import SharedWithCell from './SharedWithCell';

export type DocumentCategory = 'LEASE' | 'SOIL_SAMPLE' | 'EXPENSE' | 'OBITUARY';
export type ResponseDocumentType =
    | 'LEASE'
    | 'LAND_PARTNER'
    | 'TASK'
    | 'NOTE_ATTACHMENT'
    | 'LEASE_NOTE_ATTACHMENT'
    | 'PAYMENT';

export interface DocumentTable {
    id: string;
    fileName: string;
    fileSize: number;
    documentType: RequestDocumentType;
    sharingAllowed: boolean;
    sharedWith: SharedWith[];
    type: ResponseDocumentType;
    documentCategory: DocumentCategory;
    documentPurpose: string;
    year: number;
    createdAt: string;
    createdBy: CreatedBy;
}
export interface SharedWith {
    id: string;
    firstName: string;
    lastName: string;
}
export interface CreatedBy {
    id: string;
    firstName: string;
    lastName: string;
    profilePhoto: ProfilePhoto;
}

export interface ProfilePhoto {
    id: string;
    fileName: string;
    fileType: string;
    profilePhoto: string;
}

export default function useDocumentsTable() {
    const { type } = useContext(DocumentTypeContext);

    const { id: landPartnerId } = useParams<{ id: string }>();

    const {
        isShowing: isShowingDocument,
        file,
        isLoading: isDocumentLoading,
        handleClose: closeDocumentModal,
        handlePreviewOpen,
        handleDownload,
    } = useDownloadDocuments();

    const [isShowing, setIsShowing] = useState(false);
    const [fileName, setFileName] = useState<string>('');
    const [documentData, setDocumentData] = useState<DocumentTable | undefined>(
        undefined,
    );

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 25);

    const {
        selected: selectedRows,
        isSelected,
        toggleSelection,
        toggleSelectionAll,
        isSelectedAll,
    } = useSelection<DocumentTable>();

    const [getItems, { isLoading, data }] = useLazyDocumentsListByLPIDQuery();

    function handlePreview(documentId: string, fileName: string) {
        setFileName(fileName);
        handlePreviewOpen(documentId);
    }
    function handleClose() {
        setIsShowing(false);
        setDocumentData(undefined);
    }
    function handleModify(data: DocumentTable) {
        setDocumentData(data);
        setIsShowing(true);
    }
    function getDocuments({
        landPartnerId,
    }: {
        parentId?: string;
        landPartnerId?: string;
    }) {
        getItems({
            landPartnerId,
            sort: 'createdAt,asc',
            page: currentPage - 1,
            size: itemsPerPage,
            documentCategory: type ? type : '',
        });
    }

    useEffect(() => {
        getDocuments({ landPartnerId });
    }, [landPartnerId, type, currentPage, itemsPerPage]);

    const columns: TableColumn<DocumentTable>[] = [
        {
            header: {
                label: 'Document',
                sortable: false,
            },
            maxColumnWidth: '35%',
            cellRender: (data) => (
                <BasedTooltip text={data?.fileName}>
                    <Typography
                        onClick={() => handlePreview(data.id, data?.fileName)}
                        variant={'font14'}
                        color={'text.link'}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}>
                        {data?.fileName}
                    </Typography>
                </BasedTooltip>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Uploaded by',
                sortable: false,
            },
            cellRender: (data) => (
                <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <ProfilePhoto
                        id={data.createdBy?.id}
                        avatarFormat={{
                            size: 'small',
                            name: `${data.createdBy?.firstName} ${data.createdBy?.lastName}`,
                        }}
                    />
                    <Typography variant={'font14'}>
                        {data.createdBy?.firstName} {data.createdBy?.lastName}
                    </Typography>
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Date',
                sortable: false,
            },
            cellRender: (data) => (
                <Typography variant={'font14'}>
                    {dateTimeConverterFromUTC(data?.createdAt)}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Visibility',
                sortable: false,
            },
            cellRender: (data) => <VisionSwitch data={data} id={data.id} />,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Type',
                sortable: false,
            },
            cellRender: (data) => data.documentPurpose,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Shared with',
                sortable: false,
            },
            cellRender: (data) => {
                return <SharedWithCell data={data.sharedWith} />;
            },
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'File Info',
                sortable: false,
            },
            cellRender: (data) => (
                <Button
                    variant={'outlined'}
                    size={'small'}
                    onClick={() => handleModify(data)}>
                    Modify
                </Button>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        multiselect: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: 25,
        initialPage: 1,
        isSelected: isSelected,
        toggleSelection: toggleSelection,
        toggleSelectionAll: toggleSelectionAll,
        isSelectedAll: isSelectedAll,
        expandable: false,
    };
    const totalCount = data?.totalCount || 0;
    const documents = data?.data || [];
    const fileExtension = fileName?.split('.').pop();
    return {
        isShowing,
        handleClose,
        documentData,
        setDocumentData,
        tableConfig,
        totalCount,
        isLoading,
        documents,
        isShowingDocument,
        file,
        isDocumentLoading,
        closeDocumentModal,
        handleDownload,
        fileExtension,
        fileName,
        selectedRows,
    };
}
