import InputField, { Input } from './InputField';
import SelectField, { SelectInput } from './SelectField';
import TextareaField, { Textarea } from './TextareaField';
import UploadFileField, { UploadFile } from './UploadFileField';
import DatePickerField, { DatePickerInput } from './DatePickerField';
import RadiobuttonRow, { RadioRow } from './RadiobuttonRow';
import AutocompleteField, { Autocomplete } from './AutocompleteField';
import CheckboxRow, { Checkbox } from './CheckboxRow';
import MaskedInputField, { MaskedInputProps } from './MaskedInputField';
import SwitcherField, { Switcher } from './SwitcherField';
import RichTextField, { RichText } from './RichTextField';
import PhoneNumberInputField, { PhoneInput } from './PhoneNumberInput';
import { DistributiveOmit } from '../../interfaces';
import RangeSliderField, { RangeSlider } from './RangeSliderField';
import SearchAutocompleteField, {
    SearchAutocomplete,
} from './SearchAutocompleteField';

export const genericComponents = {
    input: InputField,
    select: SelectField,
    textarea: TextareaField,
    fileupload: UploadFileField,
    datepicker: DatePickerField,
    radio: RadiobuttonRow,
    autocomplete: AutocompleteField,
    checkbox: CheckboxRow,
    maskedInput: MaskedInputField,
    switch: SwitcherField,
    richText: RichTextField,
    phoneInput: PhoneNumberInputField,
    range: RangeSliderField,
    searchAutocomplete: SearchAutocompleteField,
};

export type TypesOfComponent =
    | Input
    | SelectInput
    | Textarea
    | UploadFile
    | DatePickerInput
    | RadioRow
    | Checkbox
    | Autocomplete
    | (MaskedInputProps & Input)
    | Switcher
    | RichText
    | RangeSlider
    | PhoneInput
    | SearchAutocomplete;

export type ComponentForm =
    | 'input'
    | 'select'
    | 'textarea'
    | 'fileupload'
    | 'datepicker'
    | 'radio'
    | 'autocomplete'
    | 'checkbox'
    | 'maskedInput'
    | 'switch'
    | 'range'
    | 'richText'
    | 'phoneInput'
    | 'searchAutocomplete';
export type FieldScheme = 'quarter' | 'third' | 'half' | 'full';
export type DrivenTypeComponent = DistributiveOmit<
    TypesOfComponent,
    'onChange'
>;
