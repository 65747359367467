import React, { useEffect, useMemo, useState } from 'react';
import { useLazyGetListOfDocumentsQuery } from '../../api/tasks/tasksAPI';
import TaskDocumentListItem, { TaskAttachment } from './TaskDocumentListItem';
import { Box, Typography } from '@mui/material';
import { useForm, useFormState } from 'react-final-form';

export default function TaskListDocuments() {
    const [deletedItems, setDeletedItems] = useState<string[]>([]);

    const { values } = useFormState();
    const form = useForm();

    const [getItems, { data, isLoading: isDocumentLoading }] =
        useLazyGetListOfDocumentsQuery();
    useEffect(() => {
        form.change('removedDocuments', deletedItems);
    }, [deletedItems]);
    const handleDelete = (id: string) => {
        setDeletedItems([...deletedItems, id]);
    };
    const filteredData = useMemo(() => {
        return data?.filter(
            (item: TaskAttachment) => !deletedItems.includes(item.id),
        );
    }, [data, deletedItems]);

    useEffect(() => {
        if (values.id) {
            getItems({ id: values.id, sort: `createdAt,desc` });
        }
    }, [values.id]);

    if (isDocumentLoading) {
        return null;
    }
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1} pt={2}>
            <Typography variant={'font12'}>Available Downloads</Typography>
            {filteredData?.map((attachment: TaskAttachment, index: number) => (
                <TaskDocumentListItem
                    attachment={attachment}
                    key={index}
                    onDelete={handleDelete}
                />
            ))}
        </Box>
    );
}
