import React from 'react';

import { Stepper as MUIStepper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import CustomStep, { StepProps } from './CustomStep';

interface OwnProps {
    isNavigable?: boolean;
    steps: StepProps[];
    orientation?: 'vertical' | 'horizontal';
    activeStep?: number;
    setActiveStep?: (step: number) => void;
}
export default function Stepper({
    setActiveStep,
    isNavigable = false,
    activeStep,
    orientation = 'vertical',
    steps,
}: OwnProps) {
    const { classes } = useStyles();
    function navigateToStep(step: number) {
        if (setActiveStep && isNavigable) {
            setActiveStep(step);
        }
    }

    return (
        <div className={classes.stepperContainer}>
            <MUIStepper
                activeStep={activeStep}
                orientation={orientation}
                alternativeLabel={orientation === 'horizontal'}>
                {steps.map(({ name, description, ...rest }, index) => (
                    <CustomStep
                        isNavigable={isNavigable}
                        onClickStep={navigateToStep}
                        key={index}
                        name={name}
                        description={description}
                        {...rest}
                    />
                ))}
            </MUIStepper>
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    stepperContainer: {
        margin: theme.spacing(4),
        padding: theme.spacing(4),
        backgroundColor: theme.palette.backgroundB.backgroundB2,
        borderRadius: theme.spacing(2),
    },
    stepLabel: {
        '& .MuiStepLabel-label': {
            fontSize: 20,
            fontWeight: 600,
        },
    },
}));
