import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';

type IntegrationTypes = 'QUICKBOOKS';

interface IntegrationResponse {
    id: string;
    type: IntegrationTypes;
    active: boolean;
}

interface ConnectQuickBooksResponse {
    qboUrl: string;
}

export const quickBooksAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        connectQuickBooks: builder.query<ConnectQuickBooksResponse, void>({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.integrations.quickBooks.connect,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        getIntegrations: builder.query<IntegrationResponse[], void>({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.integrations.root,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
    }),
});

export const { useGetIntegrationsQuery, useLazyConnectQuickBooksQuery } =
    quickBooksAPI;
