import React from 'react';

import { Box, Stack, Typography, useTheme } from '@mui/material';
import UpcomingEventsSidebar from './UpcomingEventsSidebar';
import SidebarItemsWrapper from './SidebarItemsWrapper';
import ListWithTasks from './ListWithTasks';
import ActiveAccountWidget, { ActiveAccount } from './ActiveAccountWidget';
import { useGetLandPartnersSummaryQuery } from '../../api/landpartners/landPartnersAPI';
import useManagePermission from '../../hooks/useManagePermission';

export default function ActionsSidebar() {
    const theme = useTheme();
    const { data } = useGetLandPartnersSummaryQuery({});
    const { checkPermission } = useManagePermission();

    const widgetConfig: ActiveAccount[] = [
        {
            name: 'Relationships',
            amount: data?.totalRelationships || 0,
            dynamic: 'increase',
            dynamicDescription: 'YOY',
            isHidden: !checkPermission('dashboard', 'read'),
        },
        {
            name: 'LandPartners',
            amount: data?.totalLandPartners || 0,
            dynamic: 'increase',
            dynamicDescription: 'YOY',
        },
        {
            name: 'Growers',
            amount: 66,
            dynamic: 'increase',
            dynamicDescription: 'YOY',
            isHidden:
                !checkPermission('successionPlanerDashboard', 'read') &&
                !checkPermission('farmlandManagerDashboard', 'read'),
        },
    ];

    return (
        <Stack rowGap={2} paddingLeft={2} paddingRight={2}>
            <Typography variant={'font16'} fontWeight={'bold'}>
                Active Accounts
            </Typography>
            <Box
                display={'flex'}
                justifyContent={'center'}
                gap={2}
                flexWrap={'wrap'}
                sx={{
                    padding: theme.spacing(1, 3),
                    backgroundColor: theme.palette.neutralWhite,
                    borderRadius: theme.spacing(2),
                }}>
                {widgetConfig.map((widget, index) => (
                    <ActiveAccountWidget
                        key={index}
                        lastItem={widgetConfig.length - 1 === index}
                        {...widget}
                    />
                ))}
            </Box>

            <SidebarItemsWrapper title={'Assigned Tasks'}>
                <ListWithTasks />
            </SidebarItemsWrapper>
            <SidebarItemsWrapper title={'Upcoming Events'}>
                <UpcomingEventsSidebar />
            </SidebarItemsWrapper>
        </Stack>
    );
}
