import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { BUG_REPORTS } from '../tags';

export const bugReportAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        addReport: builder.mutation({
            query: ({ screenshot, request }) => {
                const formData = new FormData();
                if (screenshot && screenshot.length > 0) {
                    screenshot.forEach((file: File) => {
                        formData.append(`screenshot`, file, file.name);
                    });
                }
                formData.append('request', JSON.stringify(request));
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.bugReports.root,
                    method: HTTP_METHOD.POST,
                    body: formData,
                    formData: true,
                };
            },
            invalidatesTags: [BUG_REPORTS.BUG_REPORTS],
        }),
    }),
});

export const { useAddReportMutation } = bugReportAPI;
