import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { DocumentItem } from './DocumentListItem';
import { useLazyGetFileQuery } from '../../api/fileApiSlice';
import { createDownloadLinkFromBlob } from '../../utils/fileHandler';
import PreviewModal from '../Modal/PreviewModal';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as DownloadIcon } from '../../assets/icons/download_icon.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as FileIcon } from '../../assets/icons/file_pdf.svg';
import StubDummyComponent from '../StubDummyComponent';
import { useLazyDownloadSharedDocumentQuery } from '../../api/sharedData/sharedDataTempAccessAPI';

interface OwnProps {
    renderData: DocumentItem;
    token?: string;
}

export default function DocumentInfo({ renderData, token }: OwnProps) {
    const [isShowing, setIsShowing] = useState(false);
    const [file, setFile] = useState<string | null>(null);
    const [
        getItems,
        { data: responsePromise, isLoading: isDocumentLoading, isFetching },
    ] = useLazyGetFileQuery();

    const [
        downloadSharedDocument,
        {
            data: sharedDocument,
            isLoading: isSharedDocumentLoading,
            isFetching: isSharedDocumentFetching,
        },
    ] = useLazyDownloadSharedDocumentQuery();

    const handleClose = () => {
        setIsShowing(false);
    };

    useEffect(() => {
        if (!responsePromise) return;
        const { data }: { data: Blob; headers: string } = responsePromise;
        const fileData = data && URL.createObjectURL(data);
        fileData && setFile(fileData);
    }, [responsePromise]);

    useEffect(() => {
        if (!sharedDocument) return;
        const { data }: { data: Blob; headers: string } = sharedDocument;
        const fileData = data && URL.createObjectURL(data);
        fileData && setFile(fileData);
    }, [sharedDocument]);

    const handlePreviewOpen = (documentId: string) => {
        if (token) {
            downloadSharedDocument({ id: documentId, token });
        } else {
            getItems(documentId);
        }
        setIsShowing(true);
    };

    const handleDownload = (documentId: string) => {
        if (token) {
            downloadSharedDocument({ id: documentId, token })
                .unwrap()
                .then(({ data }) => {
                    createDownloadLinkFromBlob(data, renderData.fileName);
                });
        } else {
            getItems(documentId)
                .unwrap()
                .then(({ data }) => {
                    createDownloadLinkFromBlob(data, renderData.fileName);
                });
        }
    };

    const extension = renderData.fileName.split('.').pop();
    if (!renderData.id)
        return <StubDummyComponent title={'No documents available'} />;

    return (
        <Box display={'flex'} columnGap={2} alignItems={'center'}>
            {renderData.id && (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    justifyContent={'center'}>
                    <Typography
                        variant="font12"
                        color={'text.secondary'}
                        fontWeight={'bold'}>
                        {'Download'}
                    </Typography>
                    <Box display={'flex'} alignItems={'center'} columnGap={2}>
                        <Typography
                            variant="font12"
                            color="text.secondary"
                            onClick={() => handlePreviewOpen(renderData.id)}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}>
                            {renderData.fileName}
                        </Typography>
                        <IconButton
                            onClick={() => handlePreviewOpen(renderData.id)}>
                            <FileIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => handleDownload(renderData.id)}>
                            <DownloadIcon />
                        </IconButton>
                    </Box>
                </Box>
            )}
            {extension && (
                <PreviewModal
                    extension={extension}
                    fileName={renderData.fileName}
                    file={file}
                    isLoading={
                        isDocumentLoading ||
                        isFetching ||
                        isSharedDocumentLoading ||
                        isSharedDocumentFetching
                    }
                    isShowing={isShowing}
                    onClose={handleClose}
                />
            )}
        </Box>
    );
}
