import { Box } from '@mui/material';
import RestoreButton from './RestoreButton';
import DeleteButton from './DeleteButton';
import useManagePermission from '../../../hooks/useManagePermission';

interface OwnProps {
    id: string;
    onRestore: (id: string) => void;
    onDelete: (id: string) => void;
}

export default function ActionsColumn({ id, onRestore, onDelete }: OwnProps) {
    const { checkPermission } = useManagePermission();

    if (!checkPermission('settings', 'modify')) {
        return null;
    }

    return (
        <Box display={'flex'} columnGap={1}>
            <RestoreButton onClick={() => onRestore(id)} />
            <DeleteButton onClick={() => onDelete(id)} />
        </Box>
    );
}
