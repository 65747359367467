import { FormConfig } from '../../design/Forms/interfaces';

import {
    composeValidators,
    isNumber,
    maxLength,
    required,
} from '../../utils/validation';
import { statusOptions } from '../../pages/Settings/Organizations/config/organizationEnums';

const organizationTypeOptions = [{ id: 'LAND_PARTNER', name: 'Land Partner' }];

export const formOrganizationConfig = (): FormConfig[] => [
    {
        formField: {
            scheme: 'half',
            name: 'name',
            type: 'input',
            label: 'Land Partner Name',
            isRequired: true,
            validation: composeValidators(required, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Name',
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'type',
            type: 'select',
            label: 'Organization Type',
        },
        renderProps: {
            placeholder: 'Select from list',
            isDisabled: true,
            size: 'small',
            options: organizationTypeOptions,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'email',
            type: 'input',
            label: 'Email',
        },
        renderProps: {
            placeholder: 'Enter Email',
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'taxId',
            type: 'input',
            label: 'Tax ID',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Tax ID',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'status',
            type: 'select',
            label: 'Status',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Select from list',
            isDisabled: false,
            size: 'small',
            options: statusOptions,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'relationshipStartDate',
            type: 'datepicker',
            label: 'Relationship Start Date',
        },
        renderProps: {
            placeholder: 'Enter Relationship Start Date',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `associatedAcres`,
            type: 'input',
            label: 'Associated Acres',
            validation: composeValidators(isNumber, maxLength(256)),
        },
        renderProps: {
            placeholder: 'Only numbers allowed',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.linkedin',
            type: 'input',
            label: 'LinkedIn (Company)',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter LinkedIn Profile',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.twitter',
            type: 'input',
            label: 'Twitter / X Profile (Company)',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Twitter Profile',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.facebook',
            type: 'input',
            label: 'Facebook (Company)',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Facebook Profile',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.instagram',
            type: 'input',
            label: 'Instagram (Company)',
            validation: composeValidators(maxLength(256)),
        },
        renderProps: {
            placeholder: 'Enter Instagram Profile',
            isDisabled: false,
            size: 'small',
        },
    },
];
