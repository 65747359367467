import { Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as FileIcon } from '../../assets/icons/file_pdf.svg';

interface OwnProps {
    file: File;
    onRemoveFile: (file: File) => void;
}
export default function FilePreview({ file, onRemoveFile }: OwnProps) {
    const { classes } = useStyles();

    return (
        <Typography
            key={file.name}
            className={classes.fileNameContainer}
            variant="font12"
            fontWeight={'medium'}
            color={'text.light'}>
            <FileIcon />
            {file.name}
            <Button
                color={'primary'}
                variant={'text'}
                onClick={() => onRemoveFile(file)}
                endIcon={<CloseIcon />}>
                Remove
            </Button>
        </Typography>
    );
}
const useStyles = makeStyles()((theme) => ({
    fileNameContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        gap: theme.spacing(1),
    },
}));
