import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import AddRowModal from './AddRowModal';
import GenericTable from '../../design/GenericTable/GenericTable';
import { nanoid } from 'nanoid';
import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    isNumber,
    isNumberArray,
    maxLength,
    maxLengthOfArray,
    minLength,
    required,
} from '../../utils/validation';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import { useTableFsaIdConfig } from './useTableFsaIdConfig';
import createDecorator from 'final-form-calculate';
import { getIn } from 'final-form';
import { Field, useFormState } from 'react-final-form';
import { numberMask } from '../../utils/fieldMasks';
import { useLocation } from 'react-router-dom';
import LumpSumForm from './LumpSumForm';
import { Option } from './WizardPageConfigs/leaseUploadConfig';

export interface FSA {
    tableId: string;
    fsaId: string;
    id: string;
    tfpNumber?: string[];
    totalAcres: number;
    tillableAcres?: number;
    landName: string;
    rentPerAcreRate?: string;
    totalAnnualRent?: string;
    description?: string;
}

interface OwnProps {
    tableData: FSA[];
    setTableData: (data: FSA[]) => void;
}

export default function LeaseDetailForm({ tableData, setTableData }: OwnProps) {
    const { state } = useLocation();
    const { values } = useFormState();
    const isLumpSum = values?.doLumpSum === 'lumpSum';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [header, setHeader] = useState<string>('Add FSA Details');
    const [config, setConfig] = useState<FormConfig[]>(
        fsaIdConfig({ isLumpSum: false }),
    );
    const [initialValues, setInitialValues] = useState<FSA | undefined>();

    const handleModelOpen = (config: FormConfig[], header: string) => {
        setConfig(config);
        setHeader(header);
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setInitialValues(undefined);
        setIsModalOpen(false);
    };

    const handleCreateOrEditRow = (rowData: FSA) => {
        const newTableData = tableData.filter((row) =>
            !row?.tableId
                ? row.fsaId !== rowData.fsaId
                : row.tableId !== rowData.tableId,
        );
        const newRowData = {
            ...rowData,
            tableId: rowData.tableId || nanoid(),
        };
        setTableData([...newTableData, newRowData]);
        setInitialValues(undefined);
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isLumpSum) {
            const totalPayment = values?.lumpSum;
            const averagePayment = totalPayment / tableData.length;
            const updatedTableData = tableData.map(
                (item) =>
                    ({
                        ...item,
                        rentPerAcreRate: !isNaN(
                            Number(
                                totalPayment /
                                    tableData.length /
                                    item.totalAcres,
                            ),
                        )
                            ? totalPayment / tableData.length / item.totalAcres
                            : 0,
                        totalAnnualRent: !isNaN(Number(averagePayment))
                            ? averagePayment
                            : 0,
                    }) as unknown as FSA,
            );
            setTableData(updatedTableData);
        }
    }, [isLumpSum, tableData.length, values]);

    useEffect(() => {
        if (state?.isCopy && !tableData?.length) {
            const fsaIds = state?.fsaIds
                ? state.fsaIds.map((item: FSA) => {
                      return {
                          ...item,
                          tableId: item.id,
                      };
                  })
                : [];
            setTableData(fsaIds);
        }
    }, [state]);

    const handleDeleteRow = (id: string) => {
        const newTableData = tableData.filter((row) => row.tableId !== id);
        setTableData(newTableData);
    };
    const handleEditRow = (id: string) => {
        const row = tableData.find((row) => row.tableId === id);
        const isField = !!row?.fsaId;
        const config = isField
            ? fsaIdConfig({ isLumpSum })
            : feesConfig({ isLumpSum });
        setConfig(config);
        setInitialValues(row);
        setHeader(isField ? 'Edit Field Details' : 'Edit Other Structure');
        setIsModalOpen(true);
    };
    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Fields',
            onClick: () => {
                handleModelOpen(
                    fsaIdConfig({ isLumpSum }),
                    'Add Field Details',
                );
            },
        },
        {
            label: 'Other Structures',
            onClick: () => {
                handleModelOpen(
                    feesConfig({ isLumpSum }),
                    'Add Other Structure',
                );
                setInitialValues({
                    rentPerAcreRate: undefined,
                } as FSA);
            },
        },
    ];

    const initialModalValue = determineInitialModalValue(
        initialValues,
        tableData,
        isLumpSum,
    );
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            alignItems={'flex-end'}>
            <LumpSumForm />
            <ActionButton label={'Add a Row'} items={actionButtonItems} />
            <Field name={'fsaIds'} component={() => null} />
            <GenericTable
                data={tableData}
                totalCount={tableData?.length}
                tableConfig={useTableFsaIdConfig(
                    true,
                    handleDeleteRow,
                    handleEditRow,
                )}
            />
            <AddRowModal
                initialValues={initialModalValue}
                decorator={totalRentCalculating}
                header={header}
                formConfig={config}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSave={handleCreateOrEditRow}
            />
        </Box>
    );
}
const totalRentCalculating = createDecorator(
    {
        field: 'rentPerAcreRate',
        updates: {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            totalAnnualRent: (rentPerAcreRate: string, allValues: any) => {
                const totalAcres = allValues
                    ? getIn(allValues, 'totalAcres')
                    : 0;
                const type = allValues ? getIn(allValues, 'type') : 'TILLABLE';
                if (!rentPerAcreRate || !totalAcres) {
                    return 0;
                }
                if (type === 'TILLABLE') {
                    return +rentPerAcreRate * +totalAcres;
                }
                return 0;
            },
        },
    },
    {
        field: 'totalAcres',
        updates: {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            totalAnnualRent: (totalAcres: string, allValues: any) => {
                const rentPerAcreRate = allValues
                    ? getIn(allValues, 'rentPerAcreRate')
                    : 0;
                const type = allValues ? getIn(allValues, 'type') : 'TILLABLE';
                if (!rentPerAcreRate || !totalAcres) {
                    return 0;
                }
                if (type === 'TILLABLE') {
                    return +rentPerAcreRate * +totalAcres;
                }
                return 0;
            },
        },
    },
);

function fsaIdConfig({ isLumpSum }: { isLumpSum: boolean }): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'full',
                name: 'type',
                type: 'radio',
                label: 'Field Type',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                options: typeOptions,
                isDisabled: false,
                size: 'third',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'fsaId',
                type: 'input',
                label: 'FSA ID',
                validation: composeValidators(
                    required,
                    minLength(1),
                    maxLength(256),
                ),
                isRequired: true,
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'landName',
                type: 'input',
                label: 'Field Name or Number (Nick Name)',
                validation: composeValidators(minLength(1), maxLength(256)),
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'tfpNumber',
                type: 'autocomplete',
                label: 'Tract/TFP Number(s)',
                validation: composeValidators(
                    maxLengthOfArray(3),
                    isNumberArray,
                ),
            },
            renderProps: {
                freeSolo: true,
                placeholder: 'Type a unique number, press Enter to add more',
                size: 'small',
                sizeSameAsInputField: true,
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'totalAcres',
                type: 'maskedInput',
                label: 'Acres',
                validation: composeValidators(
                    required,
                    // eslint-disable-next-line max-lines
                    minLength(1),
                    maxLength(256),
                    isNumber,
                ),
                isRequired: true,
            },
            renderProps: {
                prefix: '',
                mask: numberMask,
                placeholder: '',
                isDisabled: false,
                size: 'small',
            },
        },

        {
            formField: {
                scheme: 'half',
                name: 'rentPerAcreRate',
                type: 'maskedInput',
                label: 'Rent Per Acre',
                validation: composeValidators(
                    required,
                    minLength(1),
                    maxLength(256),
                    isNumber,
                ),
                isRequired: true,
            },
            renderProps: {
                prefix: '$',
                mask: numberMask,
                placeholder: '',
                isDisabled: isLumpSum,
                size: 'small',
            },
        },
        {
            formField: {
                scheme: 'half',
                name: 'totalAnnualRent',
                type: 'maskedInput',
                label: 'Total Annual Rent',
                validation: composeValidators(maxLength(256), isNumber),
            },
            renderProps: {
                prefix: '$',
                mask: numberMask,
                placeholder: 'Enter Total Annual Rent',
                isDisabled: isLumpSum,
                size: 'small',
            },
        },
        {
            formField: {
                name: 'description',
                type: 'textarea',
                label: 'Legal Land Description',
                validation: composeValidators(maxLength(1024)),
                tooltip: {
                    text: 'Example PT N HLF OF N HLF NW QTR S20 T19 R11 23.56 AC',
                    position: 'top',
                },
            },
            renderProps: {
                placeholder: 'PT N HLF OF N HLF NW QTR S20 T19 R11 23.56 AC',
                resize: 'none',
                isDisabled: false,
            },
        },
    ];
}

function feesConfig({ isLumpSum }: { isLumpSum: boolean }): FormConfig[] {
    return [
        {
            formField: {
                name: 'description',
                type: 'textarea',
                label: 'Description',
                isRequired: true,
                validation: composeValidators(
                    required,
                    minLength(1),
                    maxLength(1024),
                ),
                tooltip: {
                    text: 'Example Storage Barn',
                    position: 'top',
                },
            },
            renderProps: {
                placeholder: '',
                resize: 'none',
                isDisabled: false,
            },
        },
        {
            formField: {
                name: 'totalAnnualRent',
                type: 'maskedInput',
                label: 'Total Annual Rent',
                isRequired: true,
                validation: composeValidators(
                    required,
                    minLength(1),
                    maxLength(256),
                    isNumber,
                ),
            },
            renderProps: {
                prefix: '$',
                mask: numberMask,
                placeholder: '',
                isDisabled: isLumpSum,
                size: 'small',
            },
        },
    ];
}

function determineInitialModalValue(
    initialValues: FSA | undefined,
    tableData: FSA[],
    isLumpSum?: boolean,
): FSA | undefined {
    if (initialValues) {
        if (isLumpSum) {
            return {
                ...initialValues,
                rentPerAcreRate: 0,
                totalAnnualRent: 0,
            } as unknown as FSA;
        }
        return initialValues;
    }
    if (isLumpSum) {
        return {
            rentPerAcreRate: 0,
            totalAnnualRent: 0,
            type: 'TILLABLE',
        } as unknown as FSA;
    }
    if (tableData.length > 0) {
        return {
            rentPerAcreRate: tableData.find((item) => item.rentPerAcreRate)
                ?.rentPerAcreRate,
            type: 'TILLABLE',
        } as unknown as FSA;
    }

    return {
        rentPerAcreRate: 0,
        totalAnnualRent: 0,
        type: 'TILLABLE',
    } as unknown as FSA;
}

const typeOptions: Option[] = [
    { id: 'TILLABLE', name: 'Tillable' },
    { id: 'PASTURE', name: 'Pasture' },
    { id: 'CRP', name: 'CRP' },
    { id: 'WATERWAY', name: 'Waterway' },
];
