import AppTypography from '../../../design/AppTypography';
import { Box } from '@mui/material';
import parse from 'html-react-parser';

interface OwnProps {
    message: string;
    isHidden?: boolean;
}

export default function EventPreview({ message, isHidden }: OwnProps) {
    if (isHidden) {
        return null;
    }

    const formattedMessage = message.replace(/\r\n|\r|\n/g, '<br />');

    return (
        <Box
            display={'flex'}
            flexWrap={'wrap'}
            maxWidth={'35%'}
            flexDirection={'column'}
            rowGap={1}>
            <AppTypography
                variant={'font12'}
                color={'text.neutral9'}
                text={'Notes'}
            />
            <Box sx={{ wordBreak: 'break-word' }}>
                {parse(formattedMessage)}
            </Box>
        </Box>
    );
}
