import { ActivityCard, ActivityVariants } from '../LandPartnerActivity';
import { Box, IconButton, Typography } from '@mui/material';
import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand_icon.svg';
import theme from '../../../styles/theme';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import UserAvatar from '../../../design/Avatar/UserAvatar';
import StatusTag, { Tag } from '../../../design/StatusTag/StatusTag';
import { dateTimeConverterFromTimestamp } from '../../../utils/dateFormat';
import React from 'react';

interface OwnProps {
    activity: ActivityCard;
    isCollapsed: boolean;
    handleCollapse: () => void;
}

export default function ShortCollapsedActivity({
    activity,
    isCollapsed,
    handleCollapse,
}: OwnProps) {
    const { classes } = useStyles();
    return (
        <Box
            display={'flex'}
            sx={{
                backgroundColor: isCollapsed
                    ? theme.palette.backgroundB.backgroundB2
                    : theme.palette.backgroundB.backgroundB4,
            }}
            className={classes.expandHeader}>
            <Box
                p={theme.spacing(2, 3)}
                width={'30%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={1}>
                <Box display={'flex'} columnGap={1} alignItems={'center'}>
                    <UserAvatar name={activity.from} size={'preMedium'} />
                    <Typography
                        variant="font16"
                        fontWeight={'medium'}
                        color={'text.secondary'}>
                        {activity.from}
                    </Typography>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    pl={4.5}>
                    {isCollapsed && (
                        <Typography variant="font12">
                            {dateTimeConverterFromTimestamp(activity.createdAt)}
                        </Typography>
                    )}
                    <StatusTag {...ActivityTypeMapper[activity.type]} />
                </Box>
            </Box>
            <Box width={'50%'}>
                <Typography
                    paragraph={true}
                    noWrap={true}
                    variant={'font16'}
                    fontWeight={'medium'}
                    color={'text.secondary'}>
                    {activity.subject}
                </Typography>
            </Box>
            <Box
                width={'20%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-end'}>
                <Typography
                    variant={'font12'}
                    color={'text.link'}
                    fontWeight={'medium'}>
                    {isCollapsed ? 'Collapse' : 'Expand'}
                </Typography>
                <IconButton onClick={handleCollapse}>
                    <ExpandIcon
                        className={clsx(isCollapsed && classes.expanded)}
                    />
                </IconButton>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    expandHeader: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(0, 1),
    },
    expanded: {
        width: 24,
        height: 24,
        transform: 'rotate(180deg)',
    },
}));
const ActivityTypeMapper: Record<ActivityVariants, Tag> = {
    EMAIL: {
        text: 'Email',
        status: 'filterSuccess',
    },
    VOICE: {
        text: 'Voice',
        status: 'filterInfo',
    },
    NOTE: {
        text: 'Notes',
        status: 'filterInfo',
    },
    EVENT: {
        text: 'Event',
        status: 'filterEvent',
    },
};
