import { ORGANIZATIONS, REDUX_CACHE } from '../tags';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { getListProvidesTags } from '../generic';
import { OAKENApi } from '../OAKENApi';
import { Organization } from '../../pages/Settings/Organizations/AllOrganizations/OrganizationsList';

export const organizationsAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrganizations: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: Organization[],
                meta,
            ): { data: Organization[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, ORGANIZATIONS.ORGANIZATIONS),
        }),
        getOneOrganization: builder.query({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.root +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        addOrganization: builder.mutation({
            query: ({ companyLogo, ...values }) => {
                const formData = new FormData();
                if (companyLogo) {
                    formData.append('landPartnerLogo', companyLogo[0]);
                }
                formData.append('request', JSON.stringify(values));

                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.root,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [ORGANIZATIONS.ORGANIZATIONS],
        }),
        updateOrganization: builder.mutation({
            query: ({ companyLogo, ...values }) => {
                const formData = new FormData();
                if (companyLogo) {
                    formData.append('landPartnerLogo', companyLogo[0]);
                }
                formData.append('request', JSON.stringify(values));

                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.root +
                        `/${values.id}`,
                    method: HTTP_METHOD.PUT,
                    body: formData,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: ORGANIZATIONS.ORGANIZATIONS, id: REDUX_CACHE.LIST },
                { type: ORGANIZATIONS.ORGANIZATIONS, id: arg },
            ],
        }),
        deleteOrganization: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.root +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: ORGANIZATIONS.ORGANIZATIONS, id: REDUX_CACHE.LIST },
                { type: ORGANIZATIONS.ORGANIZATIONS, id: arg },
            ],
        }),
        getListSuborganizations: builder.query({
            query: ({ id, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.root +
                        `/${id}/suborganizations`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: Organization[],
                meta,
            ): { data: Organization[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, ORGANIZATIONS.ORGANIZATIONS),
        }),
        getUsersOfOrg: builder.query({
            query: ({ organizationId, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.users.organizationUsers +
                        `/${organizationId}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        orgDashboard: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.summary,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
    }),
});

export const {
    useGetOrganizationsQuery,
    useOrgDashboardQuery,
    useLazyGetOrganizationsQuery,
    useLazyGetOneOrganizationQuery,
    useGetOneOrganizationQuery,
    useGetUsersOfOrgQuery,
    useLazyGetUsersOfOrgQuery,
    useDeleteOrganizationMutation,
    useUpdateOrganizationMutation,
    useAddOrganizationMutation,
    //eslint-disable-next-line
    useGetListSuborganizationsQuery,
} = organizationsAPI;
