import { FormConfig } from '../../design/Forms/interfaces';
import { useForm, useFormState } from 'react-final-form';
import { Box, Button } from '@mui/material';
import FormPage from './FormPage';
import UserAvatar from '../../design/Avatar/UserAvatar';
import { useEffect, useState } from 'react';

interface PreviewPhoto {
    formConfig: FormConfig[];
    photoFieldName: string;
    fileRoute?: string;
}

export default function PreviewPhotoField({
    formConfig,
    photoFieldName,
}: PreviewPhoto) {
    const [fileURL, setFileURL] = useState<string | undefined>();
    const { values } = useFormState();
    const form = useForm();

    const handleRemoveClick = () => {
        form.change(photoFieldName, undefined);
    };
    const file = values?.[photoFieldName]?.[0];

    useEffect(() => {
        if (file instanceof File) {
            const fileURL = URL.createObjectURL(values[photoFieldName]?.[0]);
            setFileURL(fileURL);
        }
    }, [file]);

    if (file) {
        return (
            <Box display={'flex'} columnGap={2}>
                <UserAvatar
                    name={'Not Available'}
                    size={'xlarge'}
                    avatar={fileURL}
                />
                <Button variant={'text'} onClick={handleRemoveClick}>
                    Remove
                </Button>
            </Box>
        );
    }
    return <FormPage formConfig={formConfig} />;
}
