import { TableDocumentData } from './UploadBulkDocumentsModal';
import { Button, ThemeProvider, Typography } from '@mui/material';
import { tableTheme } from '../../../styles/tableTheme';
import GenericTable, { Table } from '../../../design/GenericTable/GenericTable';
import React from 'react';
import SelectField from '../../../design/Fields/SelectField';
import { documentCategoryOptions } from './utils';
import { convertBytesToMbOrKb } from '../../../utils/dataConvertor';
import theme from '../../../styles/theme';
import TextField from '@mui/material/TextField';

interface OwnProps {
    removeFile: (name: string) => void;
    tableData: TableDocumentData[];
    setTableData: (
        data: (prev: TableDocumentData[]) => TableDocumentData[],
    ) => void;
}

export default function BulkUploadTable({
    tableData,
    setTableData,
    removeFile,
}: OwnProps) {
    const tableExpensesConfig: Table<TableDocumentData> = {
        columns: [
            {
                header: {
                    label: 'Document Name',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'regular'}>
                        {tableData.documentName}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Category',
                },
                cellRender: (tableData) => (
                    <SelectField
                        fullWidth
                        height={24}
                        size={'small'}
                        value={tableData.documentCategory as string}
                        options={documentCategoryOptions}
                        onChange={(value) => {
                            setTableData(
                                (prev: TableDocumentData[]) =>
                                    prev.map((data) =>
                                        data.id === tableData.id
                                            ? {
                                                  ...data,
                                                  documentCategory: value,
                                              }
                                            : data,
                                    ) as TableDocumentData[],
                            );
                        }}
                    />
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Type',
                },
                cellRender: (tableData) => (
                    <TextField
                        onChange={(e) => {
                            setTableData(
                                (prev: TableDocumentData[]) =>
                                    prev.map((data) =>
                                        data.id === tableData.id
                                            ? {
                                                  ...data,
                                                  documentPurpose:
                                                      e.target.value,
                                              }
                                            : data,
                                    ) as TableDocumentData[],
                            );
                        }}
                        value={tableData.documentPurpose}
                        sx={{
                            '& .MuiInputBase-root': {
                                width: 120,
                                padding: 1,
                                height: 24,
                            },
                        }}
                    />
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Document Size',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {convertBytesToMbOrKb(tableData.documentSize)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Modify',
                    sortable: false,
                },
                cellRender: (tableData) => (
                    <Button
                        sx={{
                            textTransform: 'capitalize',
                            height: 24,
                            borderColor: theme.palette.neutral.neutral4,
                            color: theme.palette.neutral.neutral8,
                            borderRadius: 1,
                        }}
                        variant={'outlined'}
                        size={'small'}
                        onClick={() => {
                            setTableData(
                                (prev: TableDocumentData[]) =>
                                    prev.filter(
                                        (data) => data.id !== tableData.id,
                                    ) as TableDocumentData[],
                            );
                            removeFile(tableData.documentName);
                        }}>
                        Remove
                    </Button>
                ),
                width: 'auto',
            },
        ],
        multiselect: false,
        pageable: false,
        expandable: false,
    };

    return (
        <ThemeProvider theme={tableTheme}>
            <GenericTable data={tableData} tableConfig={tableExpensesConfig} />
        </ThemeProvider>
    );
}
