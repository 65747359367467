import React, { useEffect, useState } from 'react';
import { NoteDetailsProps } from '../Chat/NoteDetails';
import { Box } from '@mui/material';
import EditNoteField from './EditNoteField';
import NotesControls from './NotesControls';

interface OwnProps {
    data: NoteDetailsProps;
    handleEdit: (note: NoteDetailsProps) => void;
    isEdit: boolean;
    handleCancel: () => void;
}
interface UpdateNote {
    key: string;
    value: string | Date;
}
export default function EditNoteCard({
    data,
    handleEdit,
    isEdit,
    handleCancel,
}: OwnProps) {
    const [editedNote, setEditedNote] = useState(data);

    useEffect(() => {
        isEdit && setEditedNote(data);
    }, [isEdit]);

    const updateNote = ({ key, value }: UpdateNote) => {
        setEditedNote((prevState) => ({ ...prevState, [key]: value }));
    };
    const actualContactDate =
        typeof editedNote.actualContactDate === 'number'
            ? new Date(editedNote.actualContactDate * 1000)
            : editedNote.actualContactDate;
    return (
        <Box display={'flex'} width={'100%'} rowGap={2} columnGap={2} pr={2}>
            <NotesControls
                date={actualContactDate as Date}
                type={editedNote.communicationType}
                setDate={(date) =>
                    updateNote({
                        key: 'actualContactDate',
                        value: date as Date,
                    })
                }
                setType={(type) =>
                    updateNote({ key: 'communicationType', value: type })
                }
            />
            <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                <EditNoteField
                    setEditedNote={setEditedNote}
                    editedNote={editedNote}
                    handleEdit={handleEdit}
                    handleCancel={handleCancel}
                />
            </Box>
        </Box>
    );
}
