import { Option } from '../../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { FormConfig } from '../../../design/Forms/interfaces';

export const documentCategoryOptions: Option[] = [
    {
        id: 'FINANCIAL',
        name: 'Financial',
    },
    {
        id: 'FILINGS',
        name: 'Filings',
    },
    {
        id: 'REPORTS',
        name: 'Reports',
    },
    {
        id: 'OTHER',
        name: 'Other',
    },
];
export const documentCategoryFullOptions: Option[] = [
    { id: 'LEASE', name: 'Lease' },
    {
        id: 'TASK',
        name: 'Task',
    },
    {
        id: 'FINANCIAL',
        name: 'Financial',
    },
    {
        id: 'FILINGS',
        name: 'Filings',
    },
    {
        id: 'REPORTS',
        name: 'Reports',
    },
    {
        id: 'OTHER',
        name: 'Other',
    },
];
export function extractYearFromTimestamp(timestamp: number) {
    return new Date(timestamp).getFullYear();
}
interface Config {
    documentCategory: string;
}
export function fileDataConfig({ documentCategory }: Config): FormConfig[] {
    return [
        {
            formField: {
                name: 'sharingAllowed',
                type: 'switch',
                label: 'Visibility',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
            },
        },
        {
            formField: {
                name: 'documentCategory',
                type: 'select',
                label: 'Category',
            },
            renderProps: {
                isDisabled:
                    documentCategory === 'LEASE' || documentCategory === 'TASK',
                size: 'small',
                options:
                    documentCategory === 'LEASE' || documentCategory === 'TASK'
                        ? documentCategoryFullOptions
                        : documentCategoryOptions,
            },
        },
        {
            formField: {
                name: 'documentPurpose',
                type: 'input',
                label: 'Type',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
            },
        },
    ];
}
export function convertNumberOfYearToTimestamp(year: number) {
    return new Date(year, 0, 1).getTime();
}
