import React, { ChangeEvent, useState } from 'react';

import { Box, TextField } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import theme from '../../styles/theme';

import ActionButtons from './ActionButtons';
import FileUpload from './FileUpload';
import NotesControls from './NotesControls';

export interface CommunicationContainer {
    communicationType: string;
    today?: boolean;
    actualContactDate: string;
}

interface OwnProps {
    text: string;
    addNote: (
        note: string,
        file: File | null,
        communicationContainer: CommunicationContainer,
    ) => void;
    size?: 'small' | 'medium' | 'large';
    isLoading?: boolean;
    autoFocus?: boolean;
}

export default function NotesField({
    size = 'small',
    addNote,
    autoFocus = true,
    isLoading,
}: OwnProps) {
    const { classes } = useStyles();
    const [note, setNote] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const [fileValidation, setFileValidation] = useState<string>('');
    const [date, setDate] = useState<Date | null>(new Date());
    const [type, setType] = useState('PHONE_CALL');

    const resetState = () => {
        setNote('');
        setDate(new Date());
        setSelectedFile(null);
        setFileName('');
        setFileValidation('');
    };
    const handleAddNote = () => {
        addNote(note, selectedFile, {
            communicationType: type,
            actualContactDate: date ? date.toISOString() : '',
        });
        resetState();
    };

    const handleCancel = () => {
        resetState();
    };

    const validateFile = (file: File) => {
        const fileSizeInMB = file.size / (1024 * 1024);
        const fileType = file.type;
        const supportedFormats = ['application/pdf', 'image/jpeg', 'image/png'];
        if (fileSizeInMB > 10) {
            setFileValidation('Max file size supported is 10 Mb');
            setFileName('');
        } else if (!supportedFormats.includes(fileType)) {
            setFileValidation(
                'Invalid file format. Supported formats are .pdf, .jpeg, .png',
            );
            setFileName('');
        } else {
            setFileValidation('');
            setSelectedFile(file);
            setFileName(file.name);
        }
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            validateFile(file);
        }
    };

    return (
        <Box
            width={'100%'}
            display={'flex'}
            p={2}
            bgcolor={theme.palette.lightTurq.lightTurqTint1}
            flexDirection={'column'}>
            <Box width={'100%'} display={'flex'}>
                <NotesControls
                    setDate={setDate}
                    setType={setType}
                    date={date}
                    type={type}
                />
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={'80%'}
                    p={1}>
                    <TextField
                        id="note"
                        autoFocus={autoFocus}
                        fullWidth
                        variant="outlined"
                        className={classes.formControl}
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                        placeholder={'Enter text'}
                        multiline
                        minRows={
                            (size === 'small' && 3) ||
                            (size === 'medium' && 6) ||
                            12
                        }
                        maxRows={12}
                        inputProps={{ maxLength: 1024, autoFocus: autoFocus }}
                    />
                </Box>
            </Box>
            <Box
                display={'flex'}
                width={'100%'}
                alignItems={'center'}
                justifyContent={'space-between'}>
                <FileUpload
                    handleFileUpload={handleFileUpload}
                    fileName={fileName}
                    fileValidation={fileValidation}
                />
                <ActionButtons
                    isDisabled={!note || isLoading}
                    isLoading={isLoading}
                    handleCancel={handleCancel}
                    handleAddNote={handleAddNote}
                />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    formControl: {
        alignItems: 'center',
        '& .MuiFormControlLabel-label': {
            fontSize: '14px',
            fontWeight: 'bold',
        },
    },
}));
