import { PAYMENTS } from '../tags';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { getListProvidesTags } from '../generic';
import { PaymentScheduledData } from '../../features/PaymentsOverview/interfaces';

export const paymentsAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentSummary: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.schedulesSummary,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, PAYMENTS.PAYMENTS),
        }),
        getPaymentSchedule: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.schedules,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, PAYMENTS.PAYMENTS),
        }),
        getPaymentStatus: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.schedulesStatus,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, PAYMENTS.PAYMENTS),
        }),
        uploadPaymentFile: builder.mutation({
            query: ({ file, leaseId }) => {
                const formData = new FormData();
                if (file && file.length > 0) {
                    formData.append('file', file[0]);
                }
                formData.append('leaseId', leaseId);
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.upload,
                    method: HTTP_METHOD.POST,
                    body: formData,
                    formData: true,
                    responseHandler: (response) => response.text(),
                };
            },
            invalidatesTags: [PAYMENTS.PAYMENTS],
        }),
        getPaymentScheduled: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.scheduled,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: PaymentScheduledData[],
                meta,
            ): { data: PaymentScheduledData[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, PAYMENTS.PAYMENTS),
        }),
        getPaymentPaid: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.paid,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: PaymentScheduledData[],
                meta,
            ): { data: PaymentScheduledData[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, PAYMENTS.PAYMENTS),
        }),
        getPaymentStats: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.stats,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        getCsvPayments: builder.query({
            query: () => ({
                url:
                    process.env.REACT_APP_PAYMENT_API_HOST +
                    ENDPOINTS.payments.scheduled +
                    '/download',
                method: HTTP_METHOD.GET,
                cache: 'no-cache',
                responseHandler: async (response: Response) => {
                    const headers = response.headers.get('content-disposition');
                    const data = await response.blob();
                    return { data, headers };
                },
            }),
        }),
        getPaymentFilter: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.scheduled +
                        `/filters`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, PAYMENTS.PAYMENTS),
        }),
        addPaymentFilter: builder.mutation({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.scheduled +
                        `/filters`,
                    method: HTTP_METHOD.POST,
                    body: params,
                };
            },
            invalidatesTags: [PAYMENTS.PAYMENTS],
        }),
        deletePaymentFilter: builder.mutation({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.scheduled +
                        `/filters/${params.id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: [PAYMENTS.PAYMENTS],
        }),
        addPaymentInfo: builder.mutation({
            query: ({ id, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.payments.scheduled +
                        `/${id}/pay`,
                    method: HTTP_METHOD.POST,
                    body: params,
                };
            },
            invalidatesTags: [PAYMENTS.PAYMENTS],
        }),
        connectToQuickBooks: builder.mutation({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_PAYMENT_API_HOST +
                        ENDPOINTS.qbo.connectToQuickBooks,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            invalidatesTags: [PAYMENTS.PAYMENTS]
        }),
    }),
});

export const {
    useAddPaymentInfoMutation,
    useDeletePaymentFilterMutation,
    useGetPaymentFilterQuery,
    useAddPaymentFilterMutation,
    useGetPaymentStatsQuery,
    useGetPaymentPaidQuery,
    useGetPaymentScheduledQuery,
    useGetPaymentSummaryQuery,
    useGetPaymentScheduleQuery,
    useGetPaymentStatusQuery,
    useUploadPaymentFileMutation,
    useLazyGetCsvPaymentsQuery,
    useConnectToQuickBooksMutation,
} = paymentsAPI;
