import { MouseEvent, useState } from 'react';

import { debounce } from 'lodash';
import { useLazyFindExistingContactQuery } from '../../api/contacts/contactsAPI';

interface OwnProps {
    landPartnerId: string | null;
}
export const useSearchContact = ({ landPartnerId }: OwnProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const [getItems, { data: fetchedData }] = useLazyFindExistingContactQuery();

    const debouncedSearch = debounce((value: string) => {
        if (value.length >= 2) {
            getItems(
                landPartnerId
                    ? { landPartnerId: landPartnerId, nameLike: value }
                    : { nameLike: value },
            );
        }
    }, 300);

    return {
        anchorEl,
        open,
        handlePopoverOpen,
        handlePopoverClose,
        debouncedSearch,
        fetchedData,
    };
};
