import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState, useRef } from 'react';

export interface ActionMenuItem {
    label: string;
    hidden?: boolean;
    onClick: () => void;
}
export interface ActionButtonProps {
    label: string;
    items: ActionMenuItem[];
    disabled?: boolean;
    hidden?: boolean;
}

export default function ActionButton({
    label,
    items,
    hidden,
    disabled,
}: ActionButtonProps) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    if (hidden) return null;

    const handleToggle = () => {
        setIsOpen((prevOpen) => !prevOpen);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <div>
            <Button
                ref={buttonRef}
                aria-haspopup="true"
                variant={'contained'}
                size={'medium'}
                disabled={disabled}
                onClick={handleToggle}
                endIcon={<KeyboardArrowDownIcon />}>
                {label}
            </Button>
            <Menu
                anchorEl={buttonRef?.current}
                open={isOpen}
                onClose={handleClose}>
                {items.map(
                    ({ hidden, label, onClick }, index) =>
                        !hidden && (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    onClick();
                                    handleClose();
                                }}>
                                {label}
                            </MenuItem>
                        ),
                )}
            </Menu>
        </div>
    );
}
