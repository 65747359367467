import { Box } from '@mui/material';
import React from 'react';
import ActionButton, {
    ActionMenuItem,
} from '../../../design/Buttons/ActionButton';
import { useDownloadByIdsAsZipMutation } from '../../../api/documents/documentsAPI';
import { createDownloadLinkFromBlob } from '../../../utils/fileHandler';
import customToastWithAlert from '../../../design/CustomToastWithAlert';
import FilterList from './FilterList';
interface OwnProps {
    selectedDocuments: Record<string, string[]>;
}
export default function DocumentsViewActions({ selectedDocuments }: OwnProps) {
    const arrayOfDocuments = extractUniqeIdFromDocuments(selectedDocuments);
    const countOfDocuments = arrayOfDocuments.length;
    const [downloadDocumentsAsZip] = useDownloadByIdsAsZipMutation();
    const handleDownloadZip = () => {
        downloadDocumentsAsZip({
            documentIds: arrayOfDocuments,
        })
            .unwrap()
            .then((blob) => {
                createDownloadLinkFromBlob(blob, 'documents.zip');
            })
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    };

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: `Download ${countOfDocuments} documents as ZIP `,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick: handleDownloadZip,
            hidden: countOfDocuments === 0,
        },
    ];

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            width={'100%'}
            alignItems={'center'}>
            <FilterList />
            <ActionButton label={'Actions'} items={actionButtonItems} />
        </Box>
    );
}
function extractUniqeIdFromDocuments(data: Record<string, string[]>) {
    const filteredData = filterEmptyArrFromObj(data);
    return Object.keys(filteredData).flatMap((key) => filteredData[key]);
}
function filterEmptyArrFromObj(data: Record<string, string[]>) {
    return Object.keys(data).reduce(
        (acc, key) => {
            if (data[key].length) {
                acc[key] = data[key];
            }
            return acc;
        },
        {} as Record<string, string[]>,
    );
}
