import {
    TypographyCase,
    TypographyVariant,
    TypographyWeight,
} from '../styles/typography';
import { Typography } from '@mui/material';

interface TypographyProps {
    text?: string | number;
    isHidden?: boolean;
    variant?: TypographyVariant;
    fontWeight?: TypographyWeight;
    textTransform?: TypographyCase;
    flexWrap?: boolean;
    color?: string;
}

export default function AppTypography({
    text,
    isHidden,
    variant,
    fontWeight,
    color,
    textTransform,
    flexWrap = false,
}: TypographyProps) {
    if (isHidden) return null;
    if (!text) return null;
    return (
        <Typography
            noWrap={!flexWrap}
            textTransform={textTransform}
            variant={variant}
            fontWeight={fontWeight}
            color={color}
            hidden={isHidden}>
            {text}
        </Typography>
    );
}
