import React from 'react';

import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import NoteCard from './NoteCard';
import NotesField, { CommunicationContainer } from '../NotesField/NotesField';
import Loader from '../../design/BaseLoader';

import {
    extractExtensionContentDisposition,
    extractExtensionFromFileName,
    extractFileNameContentDisposition,
} from '../../utils/fileHandler';
import PreviewModal from '../Modal/PreviewModal';
import useNoteCard from './useNoteCard';
import { NoteDetailsProps } from './NoteDetails';

interface OwnProps {
    cards?: NoteDetailsProps[];
    addNote: (
        note: string,
        file: File | null,
        communicationContainer: CommunicationContainer,
    ) => void;
    type: 'LEASE' | 'CONTACT';
    isLoadingNotes?: boolean;
    isAddingNote?: boolean;
    autoFocus?: boolean;
}

export default function Chat({
    cards,
    addNote,
    type,
    isLoadingNotes,
    isAddingNote,
    autoFocus,
}: OwnProps) {
    const { classes } = useStyles();

    const {
        handlePinNote,
        handleUnpinNote,
        handleDownload,
        handleClose,
        fileName,
        handlePreviewOpen,
        isShowing,
        file,
        header,
        isLoading,
        handleEdit,
        handleArchiveNote,
    } = useNoteCard({ type, notes: cards });

    const extension =
        type === 'LEASE'
            ? header && extractExtensionContentDisposition(header)
            : fileName && extractExtensionFromFileName(fileName);

    const handledFileName =
        type === 'LEASE'
            ? header && extractFileNameContentDisposition(header)
            : fileName;

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={2}
            padding={2}
            maxHeight={'80vh'}>
            <NotesField
                addNote={addNote}
                text="Add Note"
                isLoading={isAddingNote}
                autoFocus={autoFocus}
            />
            <Typography variant="font20" fontWeight="medium">
                Recent Notes
            </Typography>
            <div className={classes.messagesContainer}>
                {!isLoadingNotes ? (
                    cards?.map((card, index) => (
                        <NoteCard
                            type={type}
                            key={index}
                            handleEdit={handleEdit}
                            attachment={card?.attachment}
                            data={card}
                            handlePinNote={handlePinNote}
                            handleUnpinNote={handleUnpinNote}
                            handleArchiveNote={handleArchiveNote}
                            onPreviewOpen={() =>
                                handlePreviewOpen({
                                    id: card?.attachment?.id,
                                    url: card?.attachment?.link,
                                    fileName: card?.attachment?.name,
                                })
                            }
                            onDownload={() =>
                                handleDownload({
                                    id: card?.attachment?.id,
                                    url: card?.attachment?.link,
                                })
                            }
                        />
                    ))
                ) : (
                    <Loader size={'small'} />
                )}

                <PreviewModal
                    extension={extension ?? ''}
                    fileName={handledFileName}
                    file={file}
                    isShowing={isShowing}
                    onClose={handleClose}
                    isLoading={isLoading}
                />
            </div>
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    messagesContainer: {
        padding: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: 10,
            backgroundColor: 'transparent',
            padding: 10,
            scrollbarHeight: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 5,
            height: 5,
            borderRadius: '5px',
            backgroundColor: 'silver',
        },
    },
}));
