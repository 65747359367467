import React from 'react';

import { Box, Checkbox, Typography, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles/createTheme';
import clsx from 'clsx';
import StatusTag from '../../design/StatusTag/StatusTag';
import { formatCurrency } from '../../utils/fieldMasks';

interface PaymentHistory {
    title: string;
    amount: string | number;
    date: string;
    status: 'PAID' | 'UNPAID' | 'UPCOMING' | 'OVERDUE';
    isChecked?: boolean;
    isSelected?: boolean;
    onClick: () => void;
}
export default function PaymentHistoryField({
    title,
    amount,
    date,
    status,
    isChecked,
    onClick,
    isSelected,
}: PaymentHistory) {
    const theme = useTheme();
    const { classes } = useStyles(theme);

    return (
        <div
            className={clsx(isSelected && classes.selected, classes.wrapper)}
            onClick={onClick}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}>
                <Typography variant={'font14'} fontWeight="bold">
                    {title}
                </Typography>
                <StatusTag status={status} text={status} />
            </Box>
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}>
                    <Checkbox checked={isChecked} />
                    <Typography>{date}</Typography>
                </Box>
                <Typography>{formatCurrency(amount)}</Typography>
            </Box>
        </div>
    );
}

const useStyles = makeStyles<Theme>()((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(1, 2),
        borderBottom: `solid 1px ${theme.palette.strokeS1}`,
    },
    selected: {
        backgroundColor: theme.palette.lightTurq.lightTurqTint2,
        transition: 'background-color 0.3s ease-in-out',
    },
}));
