import useTableArchiveLeaseConfig from './useTableArchiveLeaseConfig';
import GenericTable from '../../../../design/GenericTable/GenericTable';
import { Box } from '@mui/material';
import TableName from '../TableName';
import TableSkeleton from '../../../../design/Skeleton/TableSkeleton';
import React from 'react';
import ConfirmDeleteModal from '../ConfirmDeleteModal';

export default function ArchiveLeaseTable() {
    const {
        data,
        tableConfig,
        setIsShowing,
        deleteData,
        handleDelete,
        isLoading,
        isShowing,
        totalCount,
    } = useTableArchiveLeaseConfig();
    if (isLoading) {
        return <TableSkeleton />;
    }
    return (
        <Box>
            <TableName name="Leases" />
            <GenericTable
                data={data}
                tableConfig={tableConfig}
                totalCount={totalCount}
            />
            <ConfirmDeleteModal
                isShowing={isShowing}
                onClose={() => setIsShowing(false)}
                handleConfirm={() => {
                    handleDelete(deleteData?.id);
                }}
                title={`Are you sure you want to delete ${deleteData?.leaseName}`}
                info={`This action cannot be undone`}
            />
        </Box>
    );
}
