import { Grid } from '@mui/material';
import BasePieChart, {
    PieChartConfig,
} from '../../design/Charts/PieChart/BasePieChart';
import LastOutreachTable from '../LandPartners/LastOutreachTable';
import React from 'react';
export default function GrowersDashboard() {
    return (
        <Grid container item xs={12} justifyContent={'space-between'}>
            <BasePieChart
                data={activeServices}
                config={pieConfigActiveServices}
            />
            <Grid item xs={5}>
                <LastOutreachTable />
            </Grid>
        </Grid>
    );
}
const activeServices = [
    { name: 'Active', value: 450 },
    { name: 'None', value: 200 },
];
const pieConfigActiveServices: PieChartConfig = {
    summaryTitle: 'Total Contacts',
    summaryValue: '472',
    showLegend: true,
    dataKey: 'value',
    chartTitle: 'ACTIVE SERVICES',
    showTooltip: true,
};
