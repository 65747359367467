import React, { useState } from 'react';

import { Alert, AlertTitle, Box, Typography, useTheme } from '@mui/material';
import { useStyles } from './PaymentHistoryDetails.styles';

import { ReactComponent as InfoIcon } from '../../assets/icons/info_icon.svg';
import RowData from './RecepietRowData';
import { formatCurrency } from '../../utils/fieldMasks';

interface PaymentHistoryDetailsProps {
    paymentTitle: string;
    landownersName: string;
    rentPerAcre: string;
    parcelId: string;
    numberOfAcres: string;
    rentSubtotal: string;
    paymentDate: string;
    dueDate: string;
    totalRent: string;
}

export default function PaymentHistoryDetails({
    paymentTitle,
    landownersName,
    rentPerAcre,
    parcelId,
    numberOfAcres,
    rentSubtotal,
    paymentDate,
    dueDate,
    totalRent,
}: PaymentHistoryDetailsProps) {
    const theme = useTheme();
    const { classes } = useStyles(theme);
    const formattedPaymentDate = new Date(paymentDate).toLocaleDateString();
    const [isAlertOpen, setIsAlertOpen] = useState(true);

    return (
        <div className={classes.wrapper}>
            {isAlertOpen && (
                <Alert
                    icon={
                        <div className={classes.alertIcon}>
                            <InfoIcon />
                        </div>
                    }
                    severity="info"
                    onClose={() => {
                        setIsAlertOpen(false);
                    }}>
                    <AlertTitle>Savings alert</AlertTitle>
                    <Typography>
                        If you were on variable rent lease type, your rent would
                        be reduced by 10%.
                    </Typography>
                </Alert>
            )}
            <Box display={'flex'} flexDirection={'column'} gap={4}>
                <Typography variant={'font20'} fontWeight={'bold'}>
                    {formattedPaymentDate}
                </Typography>
                <b>{paymentTitle}</b>
            </Box>
            <div>
                <Typography className={classes.mainTextWrapper}>
                    <b>{landownersName}</b> has agreed to a <b>{rentPerAcre}</b>{' '}
                    per acre amount for the <b>{numberOfAcres}</b> acres on{' '}
                    <b>{`parcel ${parcelId}`}</b>
                </Typography>
                <div className={classes.bodyRecepiet}>
                    <RowData title={'Rent per acre'} value={rentPerAcre} />
                    <RowData title={'Number of acres'} value={numberOfAcres} />
                </div>
                <RowData title={'Land Rent Subtotal'} value={rentSubtotal} />
            </div>
            <Box display={'flex'} justifyContent={'space-between'}>
                <div>
                    <Typography variant={'font16'} fontWeight="bold">
                        Due Date
                    </Typography>
                    <Typography> {dueDate}</Typography>
                </div>
                <Typography variant={'font16'} fontWeight="bold">
                    Total Rent{' '}
                    <span className={classes.totalRentAmount}>
                        {formatCurrency(totalRent)}
                    </span>
                </Typography>
            </Box>
        </div>
    );
}
