import { useDownloadDocuments } from '../hooks/useDownloadDocuments';
import { Box, IconButton, Typography } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../assets/icons/download_icon.svg';
import { ReactComponent as FileIcon } from '../assets/icons/file_pdf.svg';
import { convertBytesToMbOrKb } from '../utils/dataConvertor';
import PreviewModal from './Modal/PreviewModal';
import React from 'react';

export interface FileMetadata {
    id: string;
    name: string;
    size?: number | string;
    date: string;
}

export default function FileWithDownloadButton({
    name,
    id,
    size,
    date,
}: FileMetadata) {
    const {
        isShowing,
        file,
        isLoading: isDocumentLoading,
        handleClose,
        handlePreviewOpen,
        handleDownload,
        extension,
    } = useDownloadDocuments();
    const fileExtension = name ? extension(name) : undefined;
    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            columnGap={2}
            maxWidth={700}>
            <Typography
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    },
                }}
                variant="font14"
                color="text.link"
                fontWeight={'regular'}
                onClick={() => handlePreviewOpen(id)}>
                {name}
            </Typography>
            {size && (
                <Typography variant="font14" color="text.neutral8">
                    Estimate {convertBytesToMbOrKb(size)}
                </Typography>
            )}
            <Typography variant="font14" color="text.neutral8">
                {date}
            </Typography>
            <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <IconButton onClick={() => handlePreviewOpen(id)}>
                    <FileIcon />
                </IconButton>
                <IconButton onClick={() => handleDownload(id, name)}>
                    <DownloadIcon />
                </IconButton>
            </Box>
            {fileExtension && (
                <PreviewModal
                    extension={fileExtension as string}
                    fileName={name}
                    file={file}
                    isLoading={isDocumentLoading}
                    isShowing={isShowing}
                    onClose={handleClose}
                />
            )}
        </Box>
    );
}
